import React, { useState } from "react";
import logo from "../../../Images/company_logo.png";
import { MdEmail, MdPhone } from "react-icons/md";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { TfiBackLeft } from "react-icons/tfi";
import Copyright from "../Copyright/Copyright";
import { FaMapMarkedAlt } from "react-icons/fa";

const contactInfo = [
  {
    values: [
      {
        icon: <MdEmail />,
        title: "Email",
        value: "post@geo-mongol.com",
      },
      {
        icon: <MdPhone />,
        title: "Phone",
        value: "+976 77278899",
      },
    ],
    name: "company",
  },
  {
    values: [
      { icon: <MdEmail />, title: "Email", value: "soil_lab@geo-mongol.com" },
      { icon: <MdPhone />, title: "Phone", value: "+976 77288899" },
    ],
    name: "lab",
  },
];

const maps = [
  {
    name: "lab",
    map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2673.887293554292!2d106.8599029!3d47.919218199999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d96934bfdce2a15%3A0xdcbef1569ad956f1!2z0JjQvdC20LXQvdC10YAg0LPQtdC-0LTQtdC30Lgg0KXQpdCaIFNvaWxMYWItMDc4!5e0!3m2!1sen!2sjp!4v1713956575569!5m2!1sen!2sjp",
  },
  {
    name: "company",
    map:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2673.9287038419857!2d106.88955787692475!3d47.91841696639249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d96eccc00000001%3A0xd9419ff8407d6f3c!2sEngineering%20geodesy%20LLC!5e0!3m2!1sen!2sjp!4v1713957220361!5m2!1sen!2sjp",
  },
];

const social = [
  {
    icon: <FaFacebook color="#1772e4" />,
    title: "Facebook",
    value: "https://www.facebook.com/EngineeringgeodesyLLC/",
  },
  {
    icon: <FaInstagram color="#a841a3" />,
    title: "Instagram",
    value: "https://www.instagram.com/engineering_geodesy_llc/?hl=en",
  },
];

const Contact = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [selected, setSelected] = useState("lab");

  const openLink = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };

  const goBack = () => {
    navigate(from, { replace: true });
  };

  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className="flex min-h-screen w-full flex-col items-center justify-between">
      <div className="mt-4 flex h-[70px] w-11/12 items-center justify-between rounded-xl bg-green-400 p-2 text-white">
        <button></button>
        <h1>Бидэнтэй холбогдох</h1>
        <button onClick={() => goBack()}>
          <TfiBackLeft size={30} />
        </button>
      </div>
      <div className="flex w-11/12 flex-col items-start justify-center gap-2 p-4 text-[20px] md:flex-row-reverse flex-col-reverse">
        <div className="h-[500px] w-full md:w-2/4">
          {maps.map(
            (item, index) =>
              item.name === selected && (
                <iframe
                  key={index}
                  title="engineer geodesy"
                  src={item.map}
                  width="100%"
                  height="100%"
                  style={{ border: "0" }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer"
                ></iframe>
              )
          )}
        </div>
        <div className="w-full rounded-md  md:w-2/4 bg-white rounded-md md:h-[450px] h-[800px] flex flex-col justify-between">
          <div className="w-full flex flex-col p-2">
            <h1 className="flex flex-col items-center justify-center text-[25px] font-bold">
              <img src={logo} alt="logo" className="h-24 w-24" />
              Engineering Geodesy LLC
            </h1>
            <div className="w-full">
              {contactInfo?.map((item, index) => (
                <div
                  key={index}
                  className="bg-gray-100 rounded-md p-2 mt-2 flex md:flex-row flex-col justify-between items-center"
                >
                  <h1 className="w-[150px] bg-white rounded-md h-full p-2 m-2">
                    {item.name === "lab" ? "Лаборатори" : "Оффис"}
                  </h1>
                  <div className="flex flex-col w-5/6">
                    {item.values.map((values, valueIndex) => (
                      <div
                        key={valueIndex}
                        className="flex  w-full items-center justify-start gap-2 md:w-2/4 mt-2"
                      >
                        <div>{values.icon}</div>
                        <a
                          className="underline"
                          href={
                            values.title === "Phone"
                              ? `tel:${values.value}`
                              : `mailto:${values.value}`
                          }
                        >
                          {values.value}
                        </a>
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={() => {
                      setSelected(item.name);
                      handleScrollToBottom();
                    }}
                    className="text-[25px] p-2 bg-green-400 text-white rounded-md"
                  >
                    <FaMapMarkedAlt />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-10 flex w-full bg-gray-100 p-2 rounded-br-md rounded-bl-md">
            {social?.map((item, index) => (
              <button
                onClick={() => openLink(item.value)}
                key={index}
                className="flex items-center justify-start gap-2 p-2"
              >
                <div className="text-[30px]">{item.icon}</div>
              </button>
            ))}
          </div>
        </div>
      </div>
      <Copyright />
    </div>
  );
};

export default Contact;
