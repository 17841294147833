import React, { useState } from "react";
import Logo from "../../../Images/company_logo.png";
import { FaRegUserCircle, FaToolbox } from "react-icons/fa";
import { IoIosStats } from "react-icons/io";
import { FaBagShopping, FaRegCopyright } from "react-icons/fa6";
import { CgArrowLeft, CgArrowRight, CgLogOut } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import useLogout from "../../requiredComponents/useLogOut";
import useAuth from "../../requiredComponents/hooks/useAuth";

const MenuItems = [
  {
    path: "/dashboard",
    icon: <IoIosStats />,
    name: "Дашбоард",
  },
  {
    path: "/orders",
    icon: <FaBagShopping />,
    name: "Захиалга",
  },
  {
    path: "/orders_result",
    icon: <FaBagShopping />,
    name: "Захиалга хариу",
  },
  {
    path: "/orders_payment",
    icon: <FaBagShopping />,
    name: "Захиалга төлбөр",
  },
  // {
  //   path: "/service",
  //   icon: <FaToolbox />,
  //   name: "Үйлчилгээ",
  // },
  {
    path: "/account",
    icon: <FaRegUserCircle />,
    name: "Аккаунт",
  },
];

const hidden = {
  maxWidth: 0,
  opacity: 0,
  display: "none",
  transition: "max-width 0.5s, opacity 0.2s",
  marginLeft: 0,
};
const visible = {
  maxWidth: "max-content",
  opacity: 1,
  display: "inline",
  transition: "max-width 0.5s, opacity 0.2s",
  marginLeft: "0px",
};

const Menu = ({ menuItem, long, handleExtend, handleMenu }) => {
  const navigate = useNavigate();
  const logout = useLogout();
  const [selected, setSelected] = useState("");
  const { auth } = useAuth();

  const signOut = async () => {
    await logout();
    navigate("/login", { replace: true });
  };
  return (
    <>
      <div
        style={{ transition: ".4s all ease", width: long ? "240px" : "80px" }}
        className="flex hidden h-full flex-col items-start justify-start p-2 md:flex"
      >
        <div
          className={`flex w-full ${long ? "justify-end" : "justify-center"} `}
        >
          <button onClick={() => handleExtend(!long)} className="text-[30px]">
            {long ? <CgArrowLeft /> : <CgArrowRight />}
          </button>
        </div>
        <div className="flex h-1/6 w-full items-center justify-center">
          <div className="flex flex-col items-center justify-center rounded-lg bg-white p-2">
            <img
              src={Logo}
              alt="logo"
              className={`h-12 w-12  object-contain`}
            />
            <p style={long ? visible : hidden} className="text-sm">
              Инженер Геодези ХХК
            </p>
          </div>
        </div>
        <div className="h-4/6 w-full p-2">
          {MenuItems.map((item, index) =>
            auth?.user?.permission !== "operator" ? (
              <button
                onClick={() => {
                  handleMenu(item.path);
                  setSelected(item.path);
                }}
                key={index}
                style={{
                  backgroundColor: menuItem === item.path ? "#49de80" : "white",
                  color: menuItem === item.path ? "white" : "black",
                }}
                className={`shadow-gray text-md mt-2 flex h-12 w-full items-center justify-start gap-4 rounded-md bg-white p-2 shadow-lg`}
              >
                {item.icon}
                <p style={long ? visible : hidden} className="flex-1 truncate">
                  {item.name}{" "}
                </p>
              </button>
            ) : (
              item.name !== "Admins" && (
                <button
                  onClick={() => {
                    handleMenu(item.path);
                    setSelected(item.path);
                  }}
                  key={index}
                  className={`${
                    selected === item.path ? "bg-green-300" : "bg-white"
                  } shadow-gray text-md mt-2 flex h-12 w-full items-center justify-start gap-4 rounded-md bg-white p-2 shadow-lg`}
                >
                  {item.icon}
                  <p
                    style={long ? visible : hidden}
                    className="flex-1 truncate"
                  >
                    {item.name}{" "}
                  </p>
                </button>
              )
            )
          )}
        </div>
        <div className="mt-10 w-full p-2">
          <button
            onClick={() => signOut()}
            className={`shadow-gray text-md  mt-2 flex h-12 w-full items-center justify-start gap-4 rounded-md bg-red-400 p-2 text-white shadow-lg`}
          >
            <CgLogOut />
            <p style={long ? visible : hidden} className="flex-1 truncate">
              Гарах
            </p>
          </button>
        </div>
        <div className="flex w-full items-center justify-center border-t border-[#666] p-4 text-black">
          {!long ? (
            <FaRegCopyright />
          ) : (
            <small>
              &copy; {new Date().getFullYear()} Инженер геодези ХХК. Бүх эрх
              хуулиар хамгаалагдсан.
            </small>
          )}
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 z-10 flex h-[50px] w-full flex-row items-center justify-center gap-1 bg-green-300 px-2 md:hidden">
        {MenuItems.map((item, index) =>
          auth?.user?.permission !== "operator" ? (
            <button
              onClick={() => {
                handleMenu(item.path);
                setSelected(index);
              }}
              key={index}
              style={{
                backgroundColor: menuItem === item.path ? "#49de80" : "white",
                color: menuItem === item.path ? "white" : "black",
              }}
              className={`shadow-gray text-md mt-2 flex w-full items-center justify-center gap-4 rounded-md bg-white p-1 shadow-lg md:justify-start`}
            >
              {item.icon}
              <p style={hidden} className="flex-1 truncate">
                {item.name}
              </p>
            </button>
          ) : (
            item.name !== "Admins" && (
              <button
                onClick={() => {
                  handleMenu(item.path);
                  setSelected(index);
                }}
                key={index}
                className={`${
                  selected === index ? "bg-white" : "bg-green-300"
                } md:item-center flex w-1/3 items-center justify-center rounded-md p-2 text-xl md:justify-start`}
              >
                <div className="flex md:hidden">{item.icon}</div>
                <p className="hidden flex-1 truncate text-sm md:flex">
                  {item.name}
                </p>
              </button>
            )
          )
        )}
      </div>
    </>
  );
};

export default Menu;
