import React, { useEffect, useState, lazy, Suspense } from "react";
import UserIndetifier from "./DashboardUtil/UserIndetifier";
import DashboardWrap from "./DashboardUtil/DashboardWrap";
import Menu from "./DashboardUtil/Menu";
import ComponentWrap from "./DashboardUtil/ComponentWrap";
import Loader from "../utility/Loader";

const Stats = lazy(() => import("./Dashboard/Stats"));
const Orders = lazy(() => import("./Dashboard/Orders"));
const Products = lazy(() => import("./Dashboard/Products"));
const Admins = lazy(() => import("./Dashboard/Admins"));
const Certify = lazy(() => import("./Dashboard/Certify"));
const Account = lazy(() => import("./Dashboard/A_account"));
const Users = lazy(() => import("./Dashboard/Users"));
const Fix = lazy(() => import("./Dashboard/Fix"));
const Website = lazy(() => import("./WebControl/Website"));

const Home = () => {
  const [extend, setExtend] = useState(false);
  const [menuItem, setMenuItem] = useState("");

  useEffect(() => {
    const getMenuFromLocal = async () => {
      const item = localStorage.getItem("selectedMenu");
      setMenuItem(item);
    };
    getMenuFromLocal();
  }, []);

  const handleExtend = (collapsed) => {
    // Update the state in the parent based on the collapse state from the child
    setExtend(collapsed);
  };

  const handleMenu = (item) => {
    setMenuItem(item);
    localStorage.setItem("selectedMenu", item);
  };

  function renderSelectedComponent() {
    switch (menuItem) {
      case "/fix":
        return <Fix />;
      case "/dashboard":
        return <Stats />;
      case "/website":
        return <Website />;
      case "/products":
        return <Products />;
      case "/orders":
        return <Orders />;
      case "/certify":
        return <Certify />;
      case "/users":
        return <Users />;
      case "/admins":
        return <Admins />;
      case "/account":
        return <Account />;
      default:
        return <Stats />;
    }
  }

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <DashboardWrap>
        <Menu
          menuItem={menuItem}
          long={extend}
          handleExtend={handleExtend}
          handleMenu={handleMenu}
        />
        <ComponentWrap long={extend}>
          <Suspense fallback={<Loader />}>{renderSelectedComponent()}</Suspense>
        </ComponentWrap>
      </DashboardWrap>
    </div>
  );
};

export default Home;
