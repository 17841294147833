import React from "react";
import { MdEmail } from "react-icons/md";
import { PiPhone } from "react-icons/pi";
import { PiArrowBendRightDownLight } from "react-icons/pi";

function GenericServiceIntro({ employee, content }) {
  return (
    <main className="w-[98%] md:w-full bg-white p-2 rounded-xl flex flex-col p-8 shadow-md shadow-gray-300 border border-green-400">
      <div className="w-full flex flex-col justify-start items-center pt-4">
        <img
          src={
            employee.image
              ? employee.image
              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
          }
          alt="thumbnail"
          loading="lazy"
          className="rounded-md h-[250px] w-[250px] object-cover"
        />
        <div className="w-full flex flex-col mt-4 justify-start items-center">
          <h1 className="underline text-lg font-bold w-5/6 text-center">
            {employee.name}
          </h1>
          <p className="text-md text-center">{employee.position}</p>
        </div>
        <div className="w-full mt-4 flex justify-center items-center gap-4">
          <button className="text-md rounded-md bg-green-400 text-white p-2 hover:scale-105 duration-300 easy-in trasition">
            <PiPhone size={25} />
          </button>
          <button className="text-md rounded-md bg-green-400 text-white p-2 hover:scale-105 duration-300 easy-in trasition">
            <MdEmail size={25} />
          </button>
        </div>
      </div>
      <div className="w-full p-2 md:p-4 flex flex-col">
        <p className="	text-md text-center mt-4">{content.about}</p>
        <div className="w-full">
          <div className="w-full p-2">
            {content?.additionals?.map((item, index) => (
              <div key={index} className="w-full">
                <h1 className="text-[25px] text-black font-bold text-left my-4 w-full flex justify-between items-center bg-gray-100 rounded-md p-2">
                  {item.productCategory} <PiArrowBendRightDownLight />
                </h1>
                <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-4">
                  {item?.products?.map((product, id) => (
                    <div
                      key={id}
                      className="text-md text-gray-500 font-bold text-left mt-4 flex flex-col rounded-md shadow-md shadow-gray-300 p-4 justify-center items-center"
                    >
                      <img
                        src={
                          product.image
                            ? product.image
                            : "https://t4.ftcdn.net/jpg/00/89/55/15/360_F_89551596_LdHAZRwz3i4EM4J0NHNHy2hEUYDfXc0j.jpg"
                        }
                        loading="lazy"
                        alt="product"
                        className="h-48 w-48 rounded-md  object-contain"
                      />
                      <div className="w-full text-left">
                        <h1>{product.name}</h1>
                        <h1>{product.rate}</h1>
                        <h1>{product.price}</h1>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}

export default GenericServiceIntro;
