import React from "react";
import GenericGroupIntro from "../../utilityComponents/customComponents/GenericGroupIntro";
import GenericPage from "../../utilityComponents/customComponents/GenericPage";

const employee = {
  image:
    "https://web.archive.org/web/20220121192205im_/http://geo-mongol.mn/uploads/202102/110634316erkhmee.jpg",
  name: "Б.Эрхэмбаяр Хот төлөвлөлтийн мэргэшсэн инженер",
  position: "Барилга, хот байгуулалтын группын дарга",
  phone: "",
  email: "",
};

const content = {
  about:
    "Барилга, архитектур, хот байгуулалтын чиглэлээр үйл ажиллагаа явуулаад өдгөө 10 жил болж байна. Монгол улсын Засгийн газрын захиалгаар Баруун бүсийн хөгжлийн төсөл, бүсийн хөгжлийн тулгуур Төв, Эрдэнэт, Өндөрхаан, Улиастай, орон нутгийн хөгжлийн хот Алтай, Улаангом, Баруун-Урт, Чойр, Сүхбаатар, Цэцэрлэг, Мөрөн хотууд Өмнөговь, Булган, Архангай, Говь-Алтай, Дорнод, Дорноговь, Төв, Сүхбаатар, Баян-Өлгий, Өвөрхангай аймгуудын 50 гаруй сум, Айвенхоу майнз ИНК компанийн захиалгаар Ханбогд, Сэлэнгэ аймгийн Зүүнхараа хот болон сумын төвийн хөгжлийн ерөнхий төлөвлөгөө мөн хот байгуулалтын хэсэгчилсэн төлөвлөлтийг гүйцэтгэж, улс эх орны хөгжилд хувь нэмэр оруулж байна.",
  additionals: [
    {
      name: "Үйл ажилгаа:",
      services: [
        "- Бүс нутгийн хөгжлийн төсөл, чөлөөт бүс, аялал жуулчлал, дэд бүтцийн хөгжлийн төсөл, ерөнхий төлөвлөгөө, хот төлөвлөлтийн суурь судалгаа",
        "- Хот, тосгоны ерөнхий төлөвлөгөө, хэсэгчилсэн ерөнхий төлөвлөгөө, хот төлөвлөлтийн суурь судалгаа",
        "- 1-10 хүртэл давхар барилга байгууламжийн барилга архитектур, орчны ерөнхий төлөвлөгөө, тохижилт, өндөржилт, барилга бүтэцийн зураг төсөл, барилгын зохион байгуулалтын зураг төсөл. /БЗБТ, АТГ, ҮЗБТ/",
        "- Хот тосгоны ерөнхий төлөвлөгөө, хэсэгчилсэн ерөнхий төлөвлөгөө, барилга байгууламжинд зориулсан өндөржилт төлөвлөлт.",
      ],
    },
  ],
};

function Architect() {
  return (
    <GenericPage name={"Барилга, хот байгуулалт, архитектурын групп"}>
      <GenericGroupIntro employee={employee} content={content} />
    </GenericPage>
  );
}

export default Architect;
