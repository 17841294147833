import React from "react";

const ComponentWrap = ({ children, long }) => {
  return (
    <div
      className={`h-full w-full ${
        long ? "md:w-5/6" : "md:full "
      } flex items-center justify-center bg-green-400 p-2 shadow-lg shadow-gray-400 md:rounded-md`}
    >
      {children}
    </div>
  );
};

export default ComponentWrap;
