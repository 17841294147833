import React from "react";
import GenericPage from "../../utilityComponents/customComponents/GenericPage";
import GenericServiceIntro from "../../utilityComponents/customComponents/GenericServiceIntro";

const employee = {
  image: "",
  name: "Я.Жанболат Геодезийн мэргэшсэн инженер",
  position: "Геодезийн хэмжил зүйн лабораторийн мастер инженер",
  phone: "",
  email: "",
};

const content = {
  about: "Бид дараах багаж төхөөрөмжийн түрээслэж, сургалт хийж байна.",
  additionals: [
    {
      productCategory: "GNSS",
      products: [
        {
          name: "KOLIDA K20 Дан Base",
          price: "150,000₮",
          rate: "1хоног",
          image:
            "https://geomaticsgear.com/wp-content/uploads/2020/06/Kolida-K20s-600x600-1.jpg",
        },
        {
          name: "KOLIDA K20 Дан Rover",
          price: "150,000₮",
          rate: "1хоног",
          image:
            "https://www.kolidainstrument.com/uploads/image/2019-03-19/5c9066336d1b6.jpg",
        },
        {
          name: "Radio modem",
          price: "50,000₮",
          rate: "1хоног",
          image:
            "https://www.kolidainstrument.com/uploads/image/2020-12-18/5fdc6a9cc5592.jpg",
        },
        {
          name: "Контроллэр",
          price: "95,000₮",
          rate: "1хоног",
          image:
            "https://www.kolidainstrument.com/uploads/image/2020-12-04/5fc9978262499.jpg",
        },
      ],
      combos: [
        {
          name: "Иж бүрэн (Base, rover, radio modem, controller)",
          price: "445,000₮",
          rate: "1хоног",
          image: "",
        },
      ],
    },
    {
      productCategory: "Нивелир",
      products: [
        {
          name: "SINCON 32 Иж бүрэн автомат нивелир",
          price: "35,000₮",
          rate: "1хоног",
          image:
            "https://geotescompany.com/upload/images/O1CN01XZ0wcq25konE01olw___916767565-removebg-preview.png",
        },
        {
          name: "SOKKIA SDL-50 Иж бүрэн дижитал нивелир",
          price: "250,000₮",
          rate: "1хоног",
          image:
            "https://us.sokkia.com/sites/default/files/styles/product_gallery_full_normal_1x/public/sdl50-digital-level/gallery/product_sdl50_01.jpg?itok=QiFdndZH",
        },
      ],
      combos: [],
    },
    {
      productCategory: "Теодолит",
      products: [
        {
          name: "NT-023 Иж бүрэн",
          price: "65,000₮",
          rate: "1хоног",
          image: "https://southinstrument.jp/uploadfile/products/NT-023_1.png",
        },
        {
          name: "GPSMAP",
          price: "55,000₮",
          rate: "1хоног",
          image:
            "https://www.garmin.co.jp/m/jp/g/products/GPSMAP64csx_front_navigation1.png",
        },
      ],
      combos: [],
    },
    {
      productCategory: "Мэргэжлийн дрон",
      products: [
        {
          name: "Phantom 4 RTK",
          price: "150,000₮",
          rate: "1хоног",
          image:
            "https://dji-official-fe.djicdn.com/dps/dbbe6381c54d42fb446e6e2c2db1fca2.jpg",
        },
        {
          name: "Matrice 210 RTK",
          price: "250,000₮",
          rate: "1хоног",
          image:
            "https://www1.djicdn.com/assets/uploads/b3f77cd1c549ee16f572e38df6c1a01b.png",
        },
        {
          name: "Matrice 200",
          price: "350,000₮",
          rate: "1хоног",
          image:
            "https://www2.djicdn.com/assets/uploads/1375c1a5d0cc271ea265a7db82df6625.png",
        },
        {
          name: "Parrot Anafi JAPAC",
          price: "550,000₮",
          rate: "1хоног",
          image:
            "https://www.jiw.co.jp/wp-content/themes/jiw/images/sale/anafi-main01.jpg",
        },
      ],
      combos: [],
    },
  ],
};

function EquipmentRent() {
  return (
    <GenericPage name={"Геодезийн хэмжилтийн багажны түрээс"}>
      <GenericServiceIntro employee={employee} content={content} />
    </GenericPage>
  );
}

export default EquipmentRent;
