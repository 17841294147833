import React from "react";
import GenericPage from "../../utilityComponents/customComponents/GenericPage";
import GenericGroupIntro from "../../utilityComponents/customComponents/GenericGroupIntro";

const employee = {
  image: "",
  name: "Д.Даяндорж Геодезийн мэргэшсэн инженер",
  position: "Геодезийн инженер",
  phone: "",
  email: "",
};

const content = {
  about:
    "Бид онлайн болон танхимын сургалтыг НХХЯ-ны зөвшөөрөлтэй Гео шинэчлэл сургалтын төвд албан байгууллага, дотоодын их сургуулиуд, мэргэжлийн холбоо, хувь хүмүүст захиалгаар онол, практик хослуулан чанарын өндөр төвшинд зохион байгуулж байна. Чадамжын сертификат олгоно.",
  additionals: [
    {
      name: "Сургалтын төрөл:",
      services: [
        "- Агаарын зураглалын онол практик хосолсон сургалт",
        "- Дроны бүх төрлийн дадлагажуулах практик сургалт, таны хэрэглээнд тохирсон сургалтын хөтөлбөрөөр хийнэ.",
        "- Агаарын зураглалын боловсруулалтын үе шатууд зохих программ хангамж дээр",
      ],
    },
  ],
};

function Lessons() {
  return (
    <GenericPage name={"Сургалт, Танилцуулга"}>
      <GenericGroupIntro employee={employee} content={content} />
    </GenericPage>
  );
}

export default Lessons;
