import React, { useEffect, useState } from "react";
import GenericSingleNews from "../../utilityComponents/customComponents/GenericSingleNews";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { Image } from "antd";

import axios from "../../../../api/axios";
import "./viewer.css";
import { cdnUrl } from "../../../utility/Urls";

const NewsSIngle = () => {
  const { id } = useParams();
  const [singleNews, setSingleNews] = useState({
    description: "",
    usedImages: [],
  });

  const customStyles = [
    "col-span-1", // Small size
    "col-span-2", // Medium size
    "col-span-3", // Large size
    // Add more custom styles as needed
  ];

  useEffect(() => {
    const singleNews = async () => {
      const { data } = await axios.get(`/api/website/news/single/${id}`);
      if (data.success) {
        setSingleNews(data.result);
      }
    };
    singleNews();
  }, []);

  return (
    <GenericSingleNews name={singleNews?.title}>
      {singleNews && (
        <main
          id="viewer"
          className="w-full flex flex-col justify-center items-center bg-white rounded-md border border-green-400 gap-2 px-10 py-4"
        >
          {parse(singleNews?.description)}
          <style>{`
    #viewer a {
      /* Styles for paragraphs inside #viewer */
      color: #49de80;
      font-size: 16px;
    }
     #viewer p {
      /* Styles for paragraphs inside #viewer */
      color: black;
      font-size: 16px;
      text-align: start;
      width: 100%
    }
    #viewer h1, h2, h3, h4 {
      /* Styles for paragraphs inside #viewer */
      color: black;
      font-weight: 600;
      font-size: 20px;
    }

    #viewer img {
      /* Styles for images inside #viewer */
    margin: auto;
      height: auto;
    }

    /* Add more CSS rules as needed for other elements inside #viewer */
  `}</style>
          <div className="grid grid-cols-4 gap-4">
            {singleNews?.usedImages?.map((item, index) => (
              <div
                key={index}
                className={customStyles[index % customStyles.length]}
              >
                <Image
                  src={cdnUrl + item.image}
                  alt="postImages"
                  className={`w-full object-fit rounded-md`}
                />
              </div>
            ))}
          </div>
        </main>
      )}
    </GenericSingleNews>
  );
};

export default NewsSIngle;
