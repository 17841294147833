import React from "react";
import GenericPage from "../../utilityComponents/customComponents/GenericPage";
import BoldText from "../../utilityComponents/customComponents/BoldText";

const Introduction = () => {
  return (
    <GenericPage name={"Танилцуулга"}>
      <main className="w-[98%] md:w-full bg-white p-2 rounded-xl flex flex-col p-8 shadow-md shadow-gray-300 border border-green-400">
        <div className="w-full rounded-sm  p-4">
          <h1 className="text-center  text-[30px]">
            <BoldText>Инженер Геодези ХХК</BoldText>
          </h1>
        </div>
        <div className="w-full gap-4 flex p-2 flex-col md:py-12 md:px-48">
          <p className="indent-8 text-lg text-gray-500 text-left mt-4">
            1993 онд Монголын улсын зөвлөх инженер Тугч Чойжилын Цэрэнбат
            Гурван-Эрдэнэ ХЭАА нэртэйгээр байгуулагдан зураг хайгуулын үйл
            ажиллагаа явуулж эхэлсэн. Тус компани нь дотоод гадаадын
            байгуулагуудын эрэлт хэрэгцээг богино хугацаанд илүү шинэ техник
            технологи, туршлагатай, эрдэм боловсролтой, чадварлаг
            мэргэжилтүүдийн хүчээр чанарын өндөр түвшинд гүйцэтгэж олны танил
            итгэлтэй түнш болон улам өргөжин хөгжиж байна.
          </p>
          <p className="indent-8 text-lg text-gray-500  mt-4">
            Монголын Худалдаа аж үйлдвэрийн танхим, Монголын инженер геологчидын
            холбоо, Монголын геодези, зураг зүйн холбоо, Монголын ажил олгогчлын
            холбоо, Монголын архитектурын эвлэлийн гишүүн, Монголын зураг
            төсөлчдийн холбооны гишүүн, Монголын Гео мэдээллийн холбоо,
            Нийслэлийн хэрэглэгчдийн найдвартай түнш байгууллага, Монгол улсын
            татварын албаны А зэрэглэлийн татвар төлөгч, Хөрс судлалын
            лаборатори TL-78, геодезийн хэмжил зүйн лаборатори CL-07 нь
            Стандартчилал хэмжилзүйн газраас MNS ISO/IEC 17025:2018 стандартын
            шаардлагыг ханган итгэмжлэгдсэн.
          </p>
          <p className="indent-8 text-lg text-gray-500 mt-4">
            DJI Enterprise, Pentax, SenseFly, Pix4D, Kolida, Agisoft, Geomash,
            eSurestar, Topcon фирмуудын албан ёсны төлөөлөгч бөгөөд АНУ, Япон,
            БНХАУ, ХБНГ, БНСУ, ОХУ-ын зураг төсөл хайгуулын судалгааны аж ахуй
            нэгж байгууллагуудтай хамтран ажиллаж байна. Монгол улсын хэмжлийн
            нэгдмэл байдлыг хангах тухай хуулийн хүрээнд геодезийн хэмжилтийн
            эталон багажны нэгж дамжуулалтыг гадаад дотоодын лабораториудаас
            гүйцэтгэж байгаа хувийн хэвшлийн анхны лаборатори юм.
          </p>
          <p className="indent-8 text-lg text-gray-500 text-left mt-4">
            ОХУ-ын Новосибриск, Эрхүү, Томск БНХАУ-ын WUHAN Их сургууль, Монгол
            улсын ШУТИС, МУИС болон дадлагын ажлыг удирдан явуулдаг үйлдвэрлэл
            сургалтын бааз компани юм.
          </p>
          <p className="indent-8 text-lg text-gray-500 text-left mt-4">
            Бид Монгол улсын нутаг дэвсгэрийн хэмжээнд 1993 оноос хойш
            тасралтгүй доорх чиглэлүүдээр үйл ажиллагаагаа явуулж, захиалагч
            хэрэглэгчиддээ үйлчилсээр байна.
          </p>
          <p className="text-lg text-gray-500 text-left mt-4">
            - Геодези, байр зүйн зураглал, кадастрын зураглал
          </p>
          <p className="text-lg text-gray-500 text-left mt-4">
            - Геотехник, геофизик, гидрогеологийн судалгаа, туршилт, шинжилгээ
          </p>
          <p className=" text-lg text-gray-500 text-left mt-4">
            - Газар зохион байгуулалт, газрын харилцаа, үнэлгээний ажил
          </p>
          <p className="text-lg text-gray-500 text-left mt-4">
            - Барилга, хот байгуулалтын зураг төсөл
          </p>
          <p className=" text-lg text-gray-500 text-left mt-4">
            - Хөрс судлалын итгэмжлэгдсэн лаборатори TL-078
          </p>
          <p className=" text-lg text-gray-500 text-left mt-4">
            - Геодезийн хэмжил зүйн итгэмжлэгдсэн лаборатори CL-07
          </p>
          <p className=" text-lg text-gray-500 text-left mt-4">
            - Геодезийн хэмжилтийн багаж, тоног төхөөрөмж, программ хангамжийн
            худалдаа (Мэргэжлийн дрон- НИСГЭГЧГҮЙ НИСЭХ ТӨХӨӨРӨМЖ, нивелир,
            теодолит, Totalstation, голын ёроолын зураглалын батиметрийн багаж)
          </p>
        </div>
      </main>
    </GenericPage>
  );
};

export default Introduction;
