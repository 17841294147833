import React from "react";
import GenericPage from "../../utilityComponents/customComponents/GenericPage";
import GenericGroupIntro from "../../utilityComponents/customComponents/GenericGroupIntro";

const employee = {
  image:
    "https://web.archive.org/web/20211228211831im_/http://geo-mongol.mn/uploads/202101/052900553IMG_1354%20(2).jpg",
  name: "Ч.Бурмаа Геодезийн мэргэшсэн инженер",
  position: "Геодези, геоматикийн группын дарга",
  phone: "",
  email: "",
};

const content = {
  about:
    "Геодезийн сүлжээ байгуулах, бүх төрлийн масштабын байрзүйн зураглалыг агаараас болон газраас хэмжин зураглах ажлыг дотоод гадаадын байгууллага, хувь хүний захиалгаар хийж гүйцэтгэж байна.",
  additionals: [
    {
      name: "Үйл ажилгаа:",
      services: [
        "- Байнгын ажиллагаатай навигацын суурин станц (CORS)-аар тогтмол долгион цацах үйлчилгээ болон шинээр суурилуулах ажлыг хийж гүйцэтгэнэ.",
        "- Уул уурхай, эрчим хүчний болон бусад салбаруудад хяналт, мониторингийн хэмжилт, зураглал хийх",
        "- Нисгэгчгүй нисэх төхөөрөмж (UAV)-өөр том масштабын байрзүйн зураглал, гурван хэмжээст зураглал, ил уурхайн маркшейдэрийн хэмжилт, хяналт, мониторинг хийх",
        "- Лазер сканнер (LIDAR) багажаар гурван хэмжээст зураглал үйлдэх, хиймэл дагуулын өндөр нарийвчлалтай зураг боловсруулах",
        "- Газарзүйн нэрийн судалгаа, тодруулалт, мэдээллийн сангийн ажил",
        "- Улсын геодезийн тулгуур сүлжээг навигацын (GNNS) системээр болон уламжлалт аргаар байгуулах, тэгшитгэн бодох",
        "- Газар доорх, дээрх шугам сүлжээний хайгуул судалгаа, зураглал хийх",
        "- Уул уурхай, барилга, байгууламжид байнгын ашиглагдах суурин хяналтын багаж суурилуулах",
        "- Геодезийн ажлын хяналт, шалгалт, зөвөлгөө",
        "- Төмөрзам, автозам, нисэх буудал, цахилгаан, холбоо мэдээллийн сүлжээний зураглал, зураг төсөлд зориулсан том дунд масштабын байрзүйн зураглал болон гүйцэтгэлийн зураг",
        "- Барилга, байгууламжийн угсралтын үеийн геодезийн хяналтын хэмжилтүүдийг хийх",
        "- Кадастрын бүх төрлийн зураглал, хяналт боловсруулалт",
      ],
    },
  ],
};

function Geodesy() {
  return (
    <GenericPage name={"Геодези геоматикийн групп"}>
      <GenericGroupIntro employee={employee} content={content} />
    </GenericPage>
  );
}

export default Geodesy;
