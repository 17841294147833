import React, { lazy, Suspense } from "react";
import Navbar from "../utilityComponents/Navbar";
import Footer from "../utilityComponents/Footer";
import { CgChevronUp } from "react-icons/cg";
import Loader from "../../utility/Loader";

const Body = lazy(() => import("../utilityComponents/Body"));

const LandingPage = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="flex flex-col items-center w-full md:mx-auto md:w-[1300px] relative">
      <button
        onClick={() => handleScrollToTop()}
        className="fixed rounded-full p-2 w-12 h-12 flex justify-center items-center right-4 z-10 bottom-2 bg-green-400 hover:bg-green-300 text-white text-xl"
      >
        <CgChevronUp />
      </button>
      <Navbar />
      <Suspense fallback={<Loader />}>
        <Body />
      </Suspense>
      <Footer />
    </div>
  );
};

export default LandingPage;
