import React from "react";
import Logo1 from "../../../../Images/CompanyLogos/autel.webp";
import Logo2 from "../../../../Images/CompanyLogos/dji.webp";
import Logo3 from "../../../../Images/CompanyLogos/kolida.png";
import Logo4 from "../../../../Images/CompanyLogos/garmin.png";
import Logo5 from "../../../../Images/CompanyLogos/hitarget.png";
import Logo6 from "../../../../Images/CompanyLogos/pix4dmatic.webp";
import Logo7 from "../../../../Images/CompanyLogos/agisoft.png";

import Slider from "react-slick";

const settingsForLogo = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

function LogoSlider() {
  return (
    <Slider
      className="w-full p-2 mx-auto bg-white rounded-md"
      {...settingsForLogo}
    >
      <img
        src={Logo1}
        alt="logo"
        loading="lazy"
        className=" h-[70px] bg-white  p-2 object-contain border-l-2 border-gray"
      />

      <img
        src={Logo2}
        alt="logo"
        loading="lazy"
        className=" h-[70px] bg-white  p-2 object-contain border-l-2 border-gray"
      />

      <img
        src={Logo3}
        alt="logo"
        loading="lazy"
        className=" h-[70px] bg-white  p-2 object-contain border-l-2 border-gray"
      />

      <img
        src={Logo4}
        alt="logo"
        loading="lazy"
        className=" h-[70px] bg-white  p-2 object-contain border-l-2 border-gray"
      />

      <img
        src={Logo5}
        alt="logo"
        loading="lazy"
        className=" h-[70px] bg-white  p-2 object-contain border-l-2 border-gray"
      />

      <img
        src={Logo6}
        alt="logo"
        loading="lazy"
        className="h-[70px] bg-white  p-2 object-contain border-l-2 border-gray"
      />
      <img
        src={Logo7}
        alt="logo"
        loading="lazy"
        className="h-[70px] bg-white  p-2 object-contain border-l-2 border-gray"
      />
    </Slider>
  );
}

export default LogoSlider;
