import React from "react";
import GenericPage from "../../utilityComponents/customComponents/GenericPage";
import GenericRequestPage from "../../utilityComponents/customComponents/GenericRequestPage";

function Resume() {
  return (
    <GenericPage name={"Ажлын байр"}>
      <GenericRequestPage mode={"resume"} />
    </GenericPage>
  );
}

export default Resume;
