import React from "react";
import { useNavigate } from "react-router-dom";
import NotFound from "../../Images/notfound.svg";
import { TfiBackLeft } from "react-icons/tfi";
const Missing = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <img src={NotFound} alt="notfound" className="h-3/6" />
      <h1 className="text-[30px] font-bold uppercase">Page not Found</h1>
      <button
        className="flex items-center justify-center rounded-md border bg-green-300 p-4 text-[25px]"
        onClick={() => goBack()}
      >
        Go Back <TfiBackLeft />
      </button>
    </div>
  );
};

export default Missing;
