import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Loader from "../utility/Loader";

import Logo from "../../Images/company_logo.png";

import axios from "../../api/axios";
import { BiArrowBack } from "react-icons/bi";
import { provinces } from "../utility/Province";

const stagesList = [
  { name: "Байгууллагийн мэдээлэл", stage: 1 },
  { name: "Байгууллага болон хэрэглэгч мэдээлэл", stage: 2 },
  { name: "Хэрэглэгчийн мэдээлэл", stage: 3 },
  { name: "Хэрэглэгчийн мэдээлэл шалгах", stage: 4 },
];

const Register = () => {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    address: { city: "", district: "", details: "" },
    permission: "client",
    companyName: "",
    companyNumber: "",
    positionOfClient: "",
    username: "",
    number: "",
    OTP: "",
  });
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(1);

  const [disabled, setDisabled] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  // Function to handle button click
  const handleButtonClick = () => {
    // Add your button click logic here
    console.log("Button clicked!");
    disableButtonForDuration(5); // Disable the button for 5 seconds
  };

  // Function to disable the button for a specific duration
  const disableButtonForDuration = (durationInSeconds) => {
    // Disable the button
    setDisabled(true);

    // Set the remaining time
    setRemainingTime(durationInSeconds);

    // Enable the button after the specified duration
    setTimeout(() => {
      setDisabled(false);
      setRemainingTime(0); // Reset the remaining time
    }, durationInSeconds * 1000); // Convert seconds to milliseconds
  };

  // useEffect hook to update the remaining time every second
  useEffect(() => {
    if (disabled) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000); // Update every second

      return () => clearInterval(timer);
    }
  }, [disabled]);

  const register = async () => {
    try {
      setLoading(true);
      if (form.password.trim() !== form.confirmPassword.trim()) {
        Swal.fire({
          icon: "error",
          text: "Нууц үг ижил хийх хэрэгтэй!",
        });
      }
      const { data } = await axios.post("/api/client/register", form, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      if (data.permission === "") {
        Swal.fire({
          icon: "error",
          text: "Бүртгүүлэхэд алдаа гарлаа",
        });
        setLoading(false);
        return 0;
      }
      if (data.success) {
        Swal.fire({
          icon: "success",
          text: "Амжилттай бүртгэгдлээ",
        });
        setStage(4);
        setLoading(false);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: data.result,
        });
        if (data.status === "banned") {
          setLoading(false);
          navigate("/unauthorized", { replace: true });
        }
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        text: err.message,
      });
      setLoading(false);
    }
  };

  const confirmation = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post("/api/client/confirmation", form, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      if (!data.success) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: data.result,
        });
      }
      Swal.fire({
        icon: "success",
        text: "Амжилттай бүртгэгдлээ",
      });
      setLoading(false);
      navigate("/login", { replace: true });
    } catch (err) {
      Swal.fire({
        icon: "error",
        text: err.message,
      });
      setLoading(false);
    }
  };

  const resend = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post("/api/client/resend", form, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      if (!data.success) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: data.result,
        });
      }
      Swal.fire({
        icon: "success",
        text: "Амжилттай илгээгдлээ",
      });
      setLoading(false);
    } catch (err) {
      Swal.fire({
        icon: "error",
        text: err.message,
      });
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <button
        onClick={() => navigate("/")}
        className="absolute top-4 left-4  flex justify-center bg-green-400 text-white items-center border rounded-md shadow-xl shadow-gray-200 p-2"
      >
        <BiArrowBack />
        Нүүр хуудас
      </button>
      <div className="absolute right-4 z-0 h-5/6 w-4/6 rounded-bl-[392px]	 rounded-br-[489px] rounded-tl-[823px] rounded-tr-[332px] bg-gradient-to-r from-green-300 to-green-600 shadow-lg shadow-green-500"></div>
      <div className="absolute left-4 z-0 h-5/6 w-4/6 rotate-180 rounded-bl-[392px]	 rounded-br-[489px] rounded-tl-[823px] rounded-tr-[332px] bg-gradient-to-r from-green-300 to-green-600 shadow-lg shadow-green-500"></div>
      <div className="shadow-gray  z-10 mx-auto w-full max-w-sm overflow-hidden rounded-lg border bg-white shadow-xl">
        <button
          onClick={() => navigate("/login")}
          className="w-full text-center mt-1 font-bold underline text-green-400"
        >
          Бүртгэлтэй бол нэвтрэх
        </button>
        <div className="flex flex-col justify-center px-6 py-4">
          <div className="w-full flex justify-center items-center">
            <img src={Logo} alt="logo" className="h-12 self-center" />
            <h2 className="text-end text-xl font-bold">Инженер Геодези ХХК</h2>
          </div>
          <div className="w-full flex justify-center items-center mt-2">
            {stagesList.map(
              (item, index) =>
                item.stage === stage && (
                  <div
                    key={index}
                    className="w-5/6 p-2 rounded-md border flex flex-col justify-center items-center"
                  >
                    <p className="text-center">{item.name}</p>
                    <div className="flex gap-2 justify-center items-center mt-1">
                      <div
                        className={`h-3 w-3 rounded-full ${
                          stage === 1 ? "bg-green-400" : "bg-gray-300"
                        }`}
                      ></div>
                      <div
                        className={`h-3 w-3 rounded-full ${
                          stage === 2 ? "bg-green-400" : "bg-gray-300"
                        } `}
                      ></div>
                      <div
                        className={`h-3 w-3 rounded-full ${
                          stage === 3 ? "bg-green-400" : "bg-gray-300"
                        } `}
                      ></div>
                      <div
                        className={`h-3 w-3 rounded-full ${
                          stage === 4 ? "bg-green-400" : "bg-gray-300"
                        } `}
                      ></div>
                    </div>
                  </div>
                )
            )}
          </div>

          {stage === 1 && (
            <div className="mt-2 w-full">
              <p className="text-sm font-bold mt-1">Компани РД</p>
              <input
                value={form.companyNumber}
                className="  mt-2 block w-full rounded-md border  bg-white px-4 py-2"
                type="text"
                placeholder="Компани РД"
                onChange={(e) =>
                  setForm({ ...form, companyNumber: e.target.value })
                }
              />
              <p className="text-sm font-bold mt-1">Компани нэр</p>
              <input
                value={form.companyName}
                className="  mt-2 block w-full rounded-md border  bg-white px-4 py-2"
                type="text"
                placeholder="Компани нэр"
                onChange={(e) =>
                  setForm({ ...form, companyName: e.target.value })
                }
              />
              <p className="text-sm font-bold mt-1">Аймаг/Хот</p>
              <select
                value={form.address.city}
                onChange={(e) =>
                  setForm({
                    ...form,
                    address: { ...form.address, city: e.target.value },
                  })
                }
                className=" mt-2 block w-full rounded-md border  bg-white px-4 py-2"
              >
                {provinces.map((item, index) => (
                  <option key={index} value={item.ner} label={item.ner} />
                ))}
              </select>
              <p className="text-sm font-bold mt-1">Сум/Дүүрэг</p>
              <select
                value={form.address.district}
                onChange={(e) =>
                  setForm({
                    ...form,
                    address: { ...form.address, district: e.target.value },
                  })
                }
                className=" mt-2 block w-full rounded-md border  bg-white px-4 py-2"
              >
                {provinces.map(
                  (city) =>
                    city.ner === form.address.city &&
                    city.sumd.map((sum, index) => (
                      <option key={index} value={sum} label={sum} />
                    ))
                )}
              </select>
              <p className="text-sm font-bold mt-1">Дэлгэрэнгүй хаяг</p>
              <textarea
                className="  mt-2 block w-full rounded-md border  bg-white px-4 py-2"
                type="text"
                placeholder="Хаяг"
                onChange={(e) =>
                  setForm({
                    ...form,
                    address: { ...form.address, details: e.target.value },
                  })
                }
              />
              <div className="w-full flex justify-end items-center">
                <button
                  style={{ transition: ".4s all ease" }}
                  className="transform rounded mt-2 bg-green-400 px-6 py-2 leading-5 text-white transition-colors duration-300  focus:outline-none"
                  onClick={() => setStage(2)}
                >
                  Үргэлжлүүлэх
                </button>
              </div>
            </div>
          )}
          {stage === 2 && (
            <div className="w-full h-full">
              <div className="mt-4 w-full">
                <input
                  value={form.positionOfClient}
                  className="  mt-2 block w-full rounded-md border  bg-white px-4 py-2"
                  type="text"
                  placeholder="Энэ байгууллага дахь албан тушаал"
                  onChange={(e) =>
                    setForm({ ...form, positionOfClient: e.target.value })
                  }
                />
              </div>
              <div className="w-full flex justify-between items-center">
                <button
                  style={{ transition: ".4s all ease" }}
                  className="transform rounded mt-2 bg-red-400 px-6 py-2 leading-5 text-white transition-colors duration-300  focus:outline-none"
                  onClick={() => setStage(stage - 1)}
                >
                  Буцах
                </button>
                <button
                  style={{ transition: ".4s all ease" }}
                  className="transform rounded mt-2 bg-green-400 px-6 py-2 leading-5 text-white transition-colors duration-300  focus:outline-none"
                  onClick={() => setStage(3)}
                >
                  Үргэлжлүүлэх
                </button>
              </div>
            </div>
          )}
          {stage === 3 && (
            <div className="w-full h-full">
              <div className="mt-4 w-full">
                <input
                  value={form.email}
                  className="  mt-2 block w-full rounded-md border  bg-white px-4 py-2"
                  type="email"
                  placeholder="Имайл"
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                />
              </div>
              <div className="mt-4 w-full">
                <input
                  value={form.username}
                  className="  mt-2 block w-full rounded-md border  bg-white px-4 py-2"
                  type="text"
                  placeholder="Хэрэглэгчийн нэр"
                  onChange={(e) =>
                    setForm({ ...form, username: e.target.value })
                  }
                />
              </div>

              <div className="mt-4 w-full">
                <input
                  value={form.number}
                  className="  mt-2 block w-full rounded-md border  bg-white px-4 py-2"
                  type="number"
                  placeholder="Утасний дугаар"
                  onChange={(e) => setForm({ ...form, number: e.target.value })}
                />
              </div>
              <div className="mt-4 w-full">
                <input
                  value={form.password}
                  className=" mt-2 block w-full rounded-md border   bg-white px-4 py-2"
                  type="password"
                  placeholder="Нууц үг"
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                />
              </div>
              <div className="mt-4 w-full">
                <input
                  value={form.confirmPassword}
                  className=" mt-2 block w-full rounded-md border   bg-white px-4 py-2"
                  type="password"
                  placeholder="Нууц үг давтан оруулах"
                  onChange={(e) =>
                    setForm({ ...form, confirmPassword: e.target.value })
                  }
                />
              </div>

              <div className="mt-4 flex items-center justify-between">
                <button
                  style={{ transition: ".4s all ease" }}
                  className="transform rounded mt-2 bg-red-400 px-6 py-2 leading-5 text-white transition-colors duration-300  focus:outline-none"
                  onClick={() => setStage(stage - 1)}
                >
                  Буцах
                </button>
                {loading ? (
                  <button
                    style={{ transition: ".4s all ease" }}
                    className="flex transform items-center justify-center rounded bg-[green] px-6 py-2 leading-5 text-white transition-colors duration-300  focus:outline-none"
                    type="button"
                  >
                    <Loader />
                  </button>
                ) : (
                  <button
                    style={{ transition: ".4s all ease" }}
                    className="transform rounded bg-green-400 px-6 py-2 leading-5 text-white transition-colors duration-300  focus:outline-none"
                    onClick={() => register()}
                  >
                    Бүртгүүлэх
                  </button>
                )}
              </div>
            </div>
          )}
          {stage === 4 && (
            <div className="w-full h-full">
              <div className="mt-4 w-full">
                <p>Та имайл дээр ирсэн нууц үгийг оруулна уу</p>
                <input
                  value={form.OTP}
                  className="  mt-2 block w-full rounded-md border  bg-white px-4 py-2"
                  type="number"
                  placeholder="Нэг удаагийн нууц үг"
                  onChange={(e) => setForm({ ...form, OTP: e.target.value })}
                />
              </div>
              <div className="w-full flex justify-between items-center">
                <button
                  disabled={disabled}
                  style={{ transition: ".4s all ease" }}
                  className="transform rounded bg-green-400  mt-4 px-6 py-2 leading-5 text-white transition-colors duration-300  focus:outline-none"
                  onClick={() => resend()}
                >
                  {loading ? (
                    <Loader />
                  ) : remainingTime > 0 ? (
                    <p>{remainingTime} Дахин илгээх</p>
                  ) : (
                    <p>Дахин илгээх</p>
                  )}
                </button>
                <button
                  style={{ transition: ".4s all ease" }}
                  className="transform rounded bg-green-400  mt-4 px-6 py-2 leading-5 text-white transition-colors duration-300  focus:outline-none"
                  onClick={() => confirmation()}
                >
                  {loading ? <Loader /> : "Шалгах"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;
