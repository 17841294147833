import React, { useEffect, useState, lazy, Suspense } from "react";
import DashboardWrap from "../Home/DashboardUtil/DashboardWrap";
import Menu from "./UtilComponent/Menu";
import ComponentWrap from "../Home/DashboardUtil/ComponentWrap";
import Loader from "../utility/Loader";

const Stats = lazy(() => import("../Client/ClientComponent/C_Status"));
const Orders = lazy(() => import("../Client/ClientComponent/C_Order"));
const Products = lazy(() => import("../Client/ClientComponent/C_Products"));
const Certify = lazy(() => import("../Client/ClientComponent/C_Certify"));
const Account = lazy(() => import("../Client/ClientComponent/C_Account"));
const Lab = lazy(() => import("../Client/ClientComponent/C_LabOrder"));

const Client = () => {
  const [extend, setExtend] = useState(false);
  const [menuItem, setMenuItem] = useState("");

  useEffect(() => {
    const getMenuFromLocal = async () => {
      const item = localStorage.getItem("selectedMenu");
      setMenuItem(item);
    };
    getMenuFromLocal();
  }, []);

  const handleExtend = (collapsed) => {
    // Update the state in the parent based on the collapse state from the child
    setExtend(collapsed);
  };

  const handleMenu = (item) => {
    setMenuItem(item);
    localStorage.setItem("selectedMenu", item);
  };

  function renderSelectedComponent() {
    switch (menuItem) {
      case "/products":
        return <Products />;
      case "/labOrders":
        return <Lab />;
      case "/orders":
        return <Orders />;
      case "/certify":
        return <Certify />;
      case "/account":
        return <Account />;
      default:
        return <Products />;
    }
  }

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <DashboardWrap>
        <Menu
          menuItem={menuItem}
          long={extend}
          handleExtend={handleExtend}
          handleMenu={handleMenu}
        />
        <ComponentWrap long={extend}>
          <Suspense fallback={<Loader />}>{renderSelectedComponent()}</Suspense>
        </ComponentWrap>
      </DashboardWrap>
    </div>
  );
};

export default Client;
