import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router";
import axios from "../../../api/axios";
import { cdnUrl } from "../../utility/Urls";
import { Image } from "antd";
import { FaCheckCircle } from "react-icons/fa";
import { FaX } from "react-icons/fa6";
import { GiHamburgerMenu } from "react-icons/gi";
import { TfiBackLeft } from "react-icons/tfi";
import Copyright from "../Copyright/Copyright";

const CertifyView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/login";

  const [single, setSingle] = useState({});
  const [refresh, setRefresh] = useState(0);
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    const getCertify = async () => {
      const { data } = await axios.get(`/api/certify/single/${id}`);
      if (data.success) {
        setSingle(data.payload);
      }
    };
    getCertify();
  }, [refresh]);

  const GoTo = () => {
    navigate(from, { replace: true });
  };

  const GoToContact = () => {
    navigate("/contact", { replace: false });
  };

  return (
    <>
      <div className="flex  min-h-screen w-full flex-col items-center justify-between gap-2">
        <div className="mt-4 flex h-[70px] w-11/12 items-center justify-between rounded-xl bg-green-400 p-2 text-white">
          <button className="hidden w-48 md:flex"></button>
          <h1 className="w-48 text-xl sm:text-2xl">Гэрчилгээ</h1>
          <div className="flex items-center justify-center gap-4">
            <button onClick={() => GoToContact()} className="hidden md:flex">
              Холбогдох
            </button>
            <button onClick={() => GoTo()} className="hidden md:flex">
              <TfiBackLeft size={30} />
            </button>
          </div>

          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="absolute right-5 flex p-2 md:hidden"
          >
            <GiHamburgerMenu size={30} />
          </button>

          <div
            className={`shadow-gray justify-ceter absolute right-4 top-4 flex w-48 transform flex-col items-center rounded-md bg-green-400 p-2 p-4 shadow-xl transition-transform duration-300 ease-in-out ${
              menuOpen ? "hidden translate-x-[1000px]" : "translate-x-100 flex"
            }`}
          >
            <div className="flex w-full justify-end">
              <button onClick={() => setMenuOpen(!menuOpen)}>
                <FaX size={30} />
              </button>
            </div>
            <button onClick={() => GoToContact()} className="text-[20px]">
              Холбогдох
            </button>
            <button onClick={() => GoTo()} className="text-[20px]">
              <TfiBackLeft size={30} />
            </button>
          </div>
        </div>
        <div className="shadow-gray flex flex-col items-center justify-center gap-4 rounded-md border p-4 shadow-xl">
          <div className="flex w-full items-center justify-center gap-4 md:h-48 md:w-96">
            <div className="w-full md:w-3/4">
              <div className="w-full flex-col">
                <h1>Гэрчилгээний: </h1>
                <p className="font-bold">№{single?.certifyNumber}</p>
              </div>
              <div className="w-full flex-col">
                <h1>Үйлчлүүлэгч: </h1>
                <p className="font-bold">{single?.client?.username}</p>
              </div>
              <div className="w-full flex-col">
                <h1>Дуусах огноо: </h1>
                <p className="font-bold">{single?.until}</p>
              </div>
              <div className="flex w-full flex-col">
                <h1>Захиалгын №: </h1>
                <p className="font-bold">{single?.order?.orderNumber}</p>
              </div>
            </div>
            <div className="flex h-full w-full items-center justify-center md:w-1/4">
              {single?.state === "completed" ? (
                <h1 className="text-[20px] text-green-400">
                  <FaCheckCircle />
                </h1>
              ) : (
                <h1 className="text-[20px] text-red-400">
                  <FaX />
                </h1>
              )}
            </div>
          </div>
          <div>
            {single.state === "completed" ? (
              <>
                {single.documentType !== "document" ? (
                  <div className="flex w-full flex-col items-center justify-evenly gap-2 sm:w-[500px] md:flex-row">
                    <Image
                      loading="lazy"
                      src={`${cdnUrl}${single.front}`}
                      width={200}
                      alt="front"
                      className="rounded-md"
                    />
                    <Image
                      loading="lazy"
                      src={`${cdnUrl}${single.back}`}
                      width={200}
                      alt="back"
                      className="rounded-md"
                    />
                  </div>
                ) : (
                  <div>
                    <p className="flex items-center justify-between p-2">
                      <a
                        className="rounded-md border p-2"
                        href={`${cdnUrl}${single?.document}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Файл харах
                      </a>
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div className="flex w-full flex-col items-center justify-center">
                <h1>Бид таны гэрчилгээг гүйцэтгэж байна.</h1>
                <h1>Та түр хүлээнэ үү.</h1>
              </div>
            )}
          </div>
        </div>
        <Copyright />
      </div>
    </>
  );
};

export default CertifyView;
