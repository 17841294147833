import { useNavigate, useLocation } from "react-router-dom";
import React, { useState } from "react";
import Swal from "sweetalert2";
import Loader from "../utility/Loader";
import useAuth from "../requiredComponents/hooks/useAuth";
import useToggle from "../requiredComponents/hooks/useToggle";
import Logo from "../../Images/company_logo.png";

import axios from "../../api/axios";
import { BiArrowBack } from "react-icons/bi";

const Login = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";

  const [form, setForm] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [check, toggleCheck] = useToggle("persist", false);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post("/api/admin/login", form, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      if (data.permission === "") {
        Swal.fire({
          icon: "error",
          text: "Нэвтэрхэд алдаа гарлаа",
        });
        setLoading(false);
        return 0;
      }
      if (data.success) {
        Swal.fire({
          icon: "success",
          text: "Амжилттай нэвтэрлээ",
        });
        const accessToken = data?.accessToken;
        const user = data?.user;
        const permission = data?.permission;
        setAuth({
          accessToken: accessToken,
          user: user,
          permission: permission,
        });

        if (data.permission === "admin" || data.permission === "operator") {
          setForm({ email: "", password: "" });
          navigate(from, { replace: true });
          setLoading(false);
        }
        if (data.permission === "client") {
          setForm({ email: "", password: "" });
          navigate("/client", { replace: true });
          setLoading(false);
        }
        if (data.permission === "laborant") {
          setForm({ email: "", password: "" });
          navigate("/labs", { replace: true });
          setLoading(false);
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: data.result,
        });
        if (data.status === "banned") {
          setLoading(false);
          navigate("/unauthorized", { replace: true });
        }
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        text: err.message,
      });
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <button
        onClick={() => navigate("/")}
        className="absolute top-4 left-4  flex justify-center bg-green-400 text-white items-center border rounded-md shadow-xl shadow-gray-200 p-2"
      >
        <BiArrowBack />
        Нүүр хуудас
      </button>
      <div className="absolute right-4 z-0 h-5/6 w-4/6 rounded-bl-[392px]	 rounded-br-[489px] rounded-tl-[823px] rounded-tr-[332px] bg-gradient-to-r from-green-300 to-green-600 shadow-lg shadow-green-500"></div>
      <div className="absolute left-4 z-0 h-5/6 w-4/6 rotate-180 rounded-bl-[392px]	 rounded-br-[489px] rounded-tl-[823px] rounded-tr-[332px] bg-gradient-to-r from-green-300 to-green-600 shadow-lg shadow-green-500"></div>
      <div className="shadow-gray  z-10 mx-auto w-full max-w-sm overflow-hidden rounded-lg border bg-white shadow-xl">
        <div className="flex flex-col justify-center px-6 py-4">
          <img src={Logo} alt="logo" className="h-24 self-center" />
          <h2 className="text-center text-3xl font-bold">
            Инженер Геодези ХХК
          </h2>
          <form onSubmit={login}>
            <div className="mt-4 w-full">
              <input
                className="  mt-2 block w-full rounded-md border  bg-white px-4 py-2"
                type="email"
                placeholder="Имайл"
                onChange={(e) => setForm({ ...form, email: e.target.value })}
              />
            </div>
            <div className="mt-4 w-full">
              <input
                className=" mt-2 block w-full rounded-md border   bg-white px-4 py-2"
                type="password"
                placeholder="Нууц үг"
                onChange={(e) => setForm({ ...form, password: e.target.value })}
              />
            </div>

            <div className="mt-4 flex items-center justify-between">
              <div className="flex justify-center items-center gap-2">
                <input
                  type="checkbox"
                  id="persist"
                  onChange={toggleCheck}
                  checked={check}
                />
                <label htmlFor="persist">Энэ хэрэгсэлийг санах</label>
              </div>
              {loading ? (
                <button
                  style={{ transition: ".4s all ease" }}
                  className="flex transform items-center justify-center rounded bg-[green] px-6 py-2 leading-5 text-white transition-colors duration-300  focus:outline-none"
                  type="button"
                >
                  <Loader />
                </button>
              ) : (
                <button
                  style={{ transition: ".4s all ease" }}
                  className="transform rounded bg-green-500 px-6 py-2 leading-5 text-white transition-colors duration-300  focus:outline-none"
                  type="submit"
                >
                  Нэвтрэх
                </button>
              )}
            </div>
            <div className="mt-4 flex items-center justify-center">
              <button
                onClick={() => navigate("/register")}
                className="underline font-bold text-green-400 text-center"
              >
                Шинээр бүртгүүлэх
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
