import React, { useEffect, useState } from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { PiPhone } from "react-icons/pi";
import Resume from "../../../../documents/resume.pdf";
import Swal from "sweetalert2";
import axios from "../../../../api/axios";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
const { Dragger } = Upload;

const props = {
  name: "file",
  multiple: true,
  action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

function GenericRequestPage({ mode }) {
  const contactInfo = [
    { icon: <MdEmail />, title: "mailto:", value: "post@geo-mongol.com" },
    { icon: <PiPhone />, title: "tel:", value: "+97677278899" },
  ];
  const handleOpenPDF = () => {
    window.open(Resume, "_blank");
  };

  const social = [
    {
      icon: <FaFacebook color="#1772e4" />,
      title: "Facebook",
      value: "https://www.facebook.com/EngineeringgeodesyLLC/",
    },
    {
      icon: <FaInstagram color="#a841a3" />,
      title: "Instagram",
      value: "https://www.instagram.com/engineering_geodesy_llc/?hl=en",
    },
  ];

  function generateMathProblem() {
    const operators = ["+", "-"]; // Exclude "*" and "/"
    const operator = operators[Math.floor(Math.random() * operators.length)];

    let num1 = Math.floor(Math.random() * 10) + 1;
    let num2 = Math.floor(Math.random() * 10) + 1;

    let answer;
    switch (operator) {
      case "+":
        answer = num1 + num2;
        break;
      case "-":
        answer = num1 - num2;
        break;
      default:
        throw new Error("Invalid operator");
    }

    const problem = `${num1} ${operator} ${num2}`;
    return { problem, answer };
  }

  const [refresh, setRefresh] = useState(0);

  const [requestForm, setRequesForm] = useState({
    username: "",
    email: "",
    number: "",
    title: "",
    description: "",
    answer: "",
  });

  const [verify, setVerify] = useState({
    problem: "",
    givenAnswer: "",
  });

  useEffect(() => {
    const { problem, answer } = generateMathProblem();
    setVerify({ problem: problem, givenAnswer: answer });
  }, [refresh]);

  const sendRequest = async () => {
    // if (verify.givenAnswer !== requestForm.answer) {
    //   resetForm();
    //   Swal.fire({
    //     icon: "error",
    //     text: "Хариу буруу байна дахин оролдоно уу!",
    //   });
    // } else {
    const { data } = await axios.post("/api/website/request/add", requestForm);
    if (data.success) {
      Swal.fire({
        icon: "success",
        text: "Хүсэлт илгээгдсэн. Баярлалаа!",
      });
      resetForm();
    } else {
      Swal.fire({
        icon: "error",
        text: "Алдаа гарлаа дахин оролдоно уу!",
      });
      resetForm();
    }
    // }
  };

  const resetForm = () => {
    setRefresh((old) => old + 1);
    setRequesForm({
      username: "",
      email: "",
      number: "",
      title: "",
      description: "",
      answer: "",
    });
  };

  return (
    <main className="w-[98%] md:w-full bg-white p-2 rounded-xl flex flex-col md:flex-row p-8 shadow-md shadow-gray-300 border border-green-400">
      <div className="w-full  md:w-4/6 flex flex-col md:flex-row justify-start items-center pt-4">
        <div className="w-full md:w-3/6 h-[400px] relative">
          <iframe
            title="engineer geodesy"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2673.928703841943!2d106.88955787640262!3d47.91841696639332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d96eccc00000001%3A0xd9419ff8407d6f3c!2sEngineering%20geodesy%20LLC!5e0!3m2!1sen!2sjp!4v1705168795117!5m2!1sen!2sjp"
            width="100%"
            height="100%"
            style={{ border: "0", borderRadius: "12px" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer"
          ></iframe>
          <div className="w-full md:w-96 text-white h-[400px] absolute md:top-0 md:right-[-340px] hidden md:flex flex-col">
            <div className="bg-[#333333] p-4 rounded-tl-xl rounded-tr-xl gap-4 w-full h-4/6 flex justify-center items-start flex-col">
              <h1 className="font-bold">Манай хаяг</h1>
              <p>
                Монгол улс, Улаанбаатар хот-16081 Ш/Х 26-246, Баянгол дүүрэг,
                6-р хороо,10 хороолол, Барилга 65
              </p>
              <h1 className="font-bold">Холбогдох</h1>
              {contactInfo.map((item, index) => (
                <a
                  href={`${item.title}${item.value}`}
                  key={index}
                  className="flex justify-center items-center gap-2"
                >
                  {item.icon}
                  {item.value}
                </a>
              ))}
            </div>
            <div className="bg-[#222222] rounded-bl-xl rounded-br-xl gap-4 w-full h-2/6 flex justify-center items-center flex-col p-4">
              <h1 className="font-bold text-xl">Ажиллах цаг: 9:00 - 18:00</h1>
            </div>
          </div>
        </div>
        <div className="w-full md:w-96 text-white h-[400px] md:hidden flex flex-col">
          <div className="bg-[#333333] p-4 rounded-tl-xl rounded-tr-xl gap-4 w-full h-4/6 flex justify-center items-start flex-col">
            <h1 className="font-bold">Манай хаяг</h1>
            <p>
              Монгол улс, Улаанбаатар хот-16081 Ш/Х 26-246, Баянгол дүүрэг, 6-р
              хороо,10 хороолол, Барилга 65
            </p>
            <h1 className="font-bold">Холбогдох</h1>
            {contactInfo.map((item, index) => (
              <a
                href={`${item.title}${item.value}`}
                key={index}
                className="flex justify-center items-center gap-2"
              >
                {item.icon}
                {item.value}
              </a>
            ))}
          </div>
          <div className="bg-[#222222] rounded-bl-xl rounded-br-xl gap-4 w-full h-2/6 flex justify-center items-center flex-col p-4">
            <h1 className="font-bold text-xl">Ажиллах цаг: 9:00 - 18:00</h1>
          </div>
        </div>
      </div>
      {mode === "request" && (
        <div className="w-full md:w-2/6 p-4 ">
          <h1 className="text-sm my-1">
            Та бидэнтэй холбогдох, манай үйлчилгээний талаар мэдээлэл авах,
            лабораторын шинжилгээ болон бүтээгдэхүүний талаар мэдээлэл авахыг
            хүсвэл өөрийн холбоо барих мэдээллийг үлдээнэ үү.
          </h1>
          <div className="w-full gap-2 flex flex-col">
            <input
              placeholder="Нэр"
              value={requestForm.username}
              type="username"
              className="w-full p-2 rounded-md border border-black"
              onChange={(e) =>
                setRequesForm({ ...requestForm, username: e.target.value })
              }
            />
            <input
              placeholder="Имайл"
              type="email"
              value={requestForm.email}
              className="w-full p-2 rounded-md border border-black"
              onChange={(e) =>
                setRequesForm({ ...requestForm, email: e.target.value })
              }
            />
            <input
              placeholder="Утас"
              type="cellphone"
              value={requestForm.number}
              onChange={(e) =>
                setRequesForm({ ...requestForm, number: e.target.value })
              }
              className="w-full p-2 rounded-md border border-black"
            />
          </div>
          <div className="mt-4 gap-2 flex w-full flex-col">
            <input
              placeholder="Гарчиг"
              value={requestForm.title}
              onChange={(e) =>
                setRequesForm({ ...requestForm, title: e.target.value })
              }
              type="text"
              className="w-full p-2 rounded-md border border-black"
            />
            <textarea
              value={requestForm.description}
              onChange={(e) =>
                setRequesForm({ ...requestForm, description: e.target.value })
              }
              placeholder="Зурвас"
              type="text"
              className="w-full p-2 rounded-md border border-black"
            />
          </div>
          <div className="mt-4 gap-2 flex w-full flex-col">
            {/* <input
              value={requestForm.answer}
              onChange={(e) =>
                setRequesForm({ ...requestForm, answer: e.target.value })
              }
              type="number"
              placeholder={`Дараах тооны хариуг (${verify.problem})=? оруулна уу`}
              className="w-full p-2 rounded-md border border-black"
            /> */}
            <button
              onClick={() => sendRequest()}
              className="w-full rounded-md mt-4 bg-green-400 p-2 text-white"
            >
              Илгээх
            </button>
          </div>
        </div>
      )}
      {mode === "resume" && (
        <div className="w-full md:w-2/6 p-4 ">
          <div>
            <h1>
              Ажлын байр зарлагдсан үед{" "}
              <button
                onClick={() => handleOpenPDF()}
                className="underline text-green-400"
              >
                энд дарж
              </button>
              анкетийг татан авч бөглөж{" "}
              <a
                className="underline text-green-400"
                href="mailto:post@geo-mongol.mn"
              >
                post@geo-mongol.mn
              </a>
              имэйлрүү илгээнэ үү.
            </h1>
            <h1>Уучлаарай одоогоор нээлттэй ажлын байр зарлагдаагүй байна</h1>
          </div>
          <div className="w-full">
            {/* <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from
                uploading company data or other banned files.
              </p>
            </Dragger> */}
          </div>
        </div>
      )}
    </main>
  );
}

export default GenericRequestPage;
