import Login from "./components/Auth/Login";
import Client from "./components/Client/Client";
import Home from "./components/Home/Home";
import Missing from "./components/Others/Missing";
import Unauthorized from "./components/Others/Unauthorized";
import LandingPage from "./components/Webpages/Home/LandingPage";
import Greetings from "./components/Webpages/Pages/AboutUs/Greetings";
import UnderConst from "./components/Webpages/Pages/UnderConst";
import CertifyView from "./components/requiredComponents/Certify/CertifyView";
import Contact from "./components/requiredComponents/Contact/Contact";
import Layout from "./components/requiredComponents/Layout";
import PersistLogin from "./components/requiredComponents/PersistLogin";
import RequireAuth from "./components/requiredComponents/RequireAuth";
import { Routes, Route } from "react-router-dom";
import Introduction from "./components/Webpages/Pages/AboutUs/Introduction";
import Employees from "./components/Webpages/Pages/AboutUs/Employees";
import Geodesy from "./components/Webpages/Pages/ServicePages/Geodesy";
import Architect from "./components/Webpages/Pages/ServicePages/Architect";
import LandRating from "./components/Webpages/Pages/ServicePages/LandRating";
import Geology from "./components/Webpages/Pages/ServicePages/Geology";
import Lessons from "./components/Webpages/Pages/ServicePages/Lessons";
import EquipmentRent from "./components/Webpages/Pages/ServicePages/EquipmentRent";
import CORS from "./components/Webpages/Pages/ServicePages/CORS";
import Tl78 from "./components/Webpages/Pages/Labs/Tl78";
import Cl07 from "./components/Webpages/Pages/Labs/Cl07";
import Request from "./components/Webpages/Pages/WorkForce/Request";
import Resume from "./components/Webpages/Pages/WorkForce/Resume";
import News from "./components/Webpages/Pages/news/News";
import NewsSIngle from "./components/Webpages/Pages/news/NewsSIngle";
import Register from "./components/Auth/Register";
import Lab from "./components/Lab/Lab";
import React from "react";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="c/v/:id" element={<CertifyView />} />
        {/* WebPages */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/greeting" element={<Greetings />} />
        <Route path="/introduction" element={<Introduction />} />
        <Route path="/employees" element={<Employees />} />
        {/* WebPages Groups */}
        <Route path="/geodesyGroup" element={<Geodesy />} />
        <Route path="/architecture" element={<Architect />} />
        <Route path="/landRating" element={<LandRating />} />
        <Route path="/geologyGroup" element={<Geology />} />
        {/* WebPages Services */}
        <Route path="/lesson" element={<Lessons />} />
        <Route path="/equipmentRent" element={<EquipmentRent />} />
        <Route path="/cors" element={<CORS />} />
        <Route path="/lab-tl-78" element={<Tl78 />} />
        <Route path="/lab-cl-07" element={<Cl07 />} />

        {/* WebPages */}
        <Route path="/request" element={<Request />} />
        <Route path="/resume" element={<Resume />} />

        {/* News Pages */}

        <Route path="/news" element={<News />} />
        <Route path="/singleNews/:id" element={<NewsSIngle />} />

        {/* Shop */}
        {/* <Route path="/shop" element={<ShopPage />} /> */}

        {/* we want to protect these routes */}

        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={["admin", "operator"]} />}>
            <Route path="/dashboard" element={<Home />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["client"]} />}>
            <Route path="/client" element={<Client />} />
          </Route>
          <Route
            element={
              <RequireAuth
                allowedRoles={["laborant", "accountant", "operator"]}
              />
            }
          >
            <Route path="/labs" element={<Lab />} />
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
