import React, { useEffect, useState, lazy, Suspense } from "react";
import DashboardWrap from "./DashboardUtil/DashboardWrap";
import Menu from "./DashboardUtil/Menu";
import ComponentWrap from "./DashboardUtil/ComponentWrap";
import Loader from "../utility/Loader";

const LabService = lazy(() => import("./Dashboard/Lab_service"));
const LabOrders = lazy(() => import("./Dashboard/Lab_orders"));
const LabDashboard = lazy(() => import("./Dashboard/Lab_dashboard"));
const Account = lazy(() => import("./Dashboard/A_account"));
const LabOrderResult = lazy(() => import("./Dashboard/Lab_order_result"));
const LabOrderPayment = lazy(() => import("./Dashboard/Lab_order_payment"));

const Lab = () => {
  const [extend, setExtend] = useState(false);
  const [menuItem, setMenuItem] = useState("");

  useEffect(() => {
    const getMenuFromLocal = async () => {
      const item = localStorage.getItem("selectedMenu");
      setMenuItem(item);
    };
    getMenuFromLocal();
  }, []);

  const handleExtend = (collapsed) => {
    // Update the state in the parent based on the collapse state from the child
    setExtend(collapsed);
  };

  const handleMenu = (item) => {
    setMenuItem(item);
    localStorage.setItem("selectedMenu", item);
  };

  function renderSelectedComponent() {
    switch (menuItem) {
      case "/dashboard":
        return <LabDashboard />;
      case "/account":
        return <Account />;
      case "/service":
        return <LabOrders />;
      case "/orders_result":
        return <LabOrderResult />;
      case "/orders_payment":
        return <LabOrderPayment />;
      case "/orders":
        return <LabService />;
      default:
        return <LabDashboard />;
    }
  }

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <DashboardWrap>
        <Menu
          menuItem={menuItem}
          long={extend}
          handleExtend={handleExtend}
          handleMenu={handleMenu}
        />
        <ComponentWrap long={extend}>
          <Suspense fallback={<Loader />}>{renderSelectedComponent()}</Suspense>
        </ComponentWrap>
      </DashboardWrap>
    </div>
  );
};

export default Lab;
