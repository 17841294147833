import React from "react";

const DashboardWrap = ({ children }) => {
  return (
    <div className="flex h-screen w-full flex-row items-center justify-center rounded-md bg-white shadow-xl shadow-gray-500">
      {children}
    </div>
  );
};

export default DashboardWrap;
