import React, { useEffect } from "react";

import { CgChevronUp } from "react-icons/cg";
import { useLocation, useParams } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";
import axios from "../../../../api/axios";

const GenericSingleNews = ({ name, children }) => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    handleScrollToTop();
  }, []);

  return (
    <div className="flex w-full flex-col items-center md:mx-auto md:w-[1300px] relative">
      <button
        onClick={() => handleScrollToTop()}
        className="fixed rounded-full p-2 w-12 h-12 flex justify-center items-center right-4 z-10 bottom-2 bg-green-400 hover:bg-green-300 text-white text-xl"
      >
        <CgChevronUp />
      </button>
      <Navbar />
      <div className="w-[98%] md:w-full rounded-lg bg-green-400 text-white p-4 mt-4 shadow-sm shadow-gray-400">
        <h1 className="text-center font-bold text-[22px] uppercase">{name}</h1>
      </div>
      {children}
      <Footer />
    </div>
  );
};

export default GenericSingleNews;
