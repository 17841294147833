import React from "react";
import { MdMail } from "react-icons/md";
import { PiPhone } from "react-icons/pi";
import { Space, Table, Tag } from "antd";

function GenericLabIntro({ employee, content, table }) {
  const columns = [
    {
      title: "Хэмжил зүйн ажил үйлчилгээ",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
      fixed: "left",
      width: 100,
    },
    {
      title: "Хэмжих хэрэгслийн нэр, төрөл",
      key: "contents",
      dataIndex: "contents",
      render: (_, { contents }) => (
        <>
          {contents.map((tag) => {
            return (
              <Tag color="#49de80" key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
      width: 100,
    },
    {
      title: "Хэмжлийн хязгаар",
      dataIndex: "limits",
      key: "limits",
      width: 100,
      responsive: ["md"],
    },
    {
      title: "Шалгалт тохируулга ба хэмжлийн чадамж (±)",
      dataIndex: "tests",
      key: "tests",
      width: 100,
      responsive: ["md"],
    },
  ];

  return (
    <main className="w-full bg-white p-2 rounded-xl flex flex-col p-8 shadow-md shadow-gray-300 border border-green-400">
      <div className="w-full flex justify-center items-center gap-4 flex-col">
        <h1>Лабораторийн талаар мэдэээлэл авах</h1>
        <div className="gap-4 w-full flex justify-center items-center">
          <a
            href={`tel:${employee.phone}`}
            className="px-6 py-2 rounded-md text-xl bg-green-400 text-white"
          >
            <PiPhone />
          </a>
          <a
            href={`mailto:${employee.email}`}
            className="px-6 py-2 rounded-md text-xl bg-green-400 text-white"
          >
            <MdMail />
          </a>
        </div>
      </div>
      <div className="w-full p-1 md:py-4 md:px-8">
        <p className="indent-8	text-md text-left mt-4">{content.about}</p>
        {table && (
          <div className="w-full md:p-2 md:py-4 md:px-8 mt-2">
            <Table
              columns={columns}
              dataSource={table.rows}
              pagination={{
                position: ["none", "none"],
              }}
            />
          </div>
        )}
        <div className="w-full">
          <div className="w-full p-2">
            {content?.additionals?.map((item, index) => (
              <div key={index} className="w-full">
                <h1 className="text-lg text-black font-bold text-left mt-4">
                  {item.name}
                </h1>
                {item?.services?.map((service, id) => (
                  <p
                    key={id}
                    className="text-md text-gray-500 font-bold text-left mt-4"
                  >
                    {service}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}

export default GenericLabIntro;
