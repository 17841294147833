export const provinces = [
  {
    ner: "Сонгох",
    sumd: [],
  },
  {
    ner: "Улаанбаатар",
    sumd: [
      "Сонгох",
      "Багануур",
      "Багахангай",
      "Баянгол",
      "Баянзүрх",
      "Налайх",
      "Сонгинохайрхан",
      "Сүхбаатар",
      "Хан-Уул",
      "Чингэлтэй",
    ],
  },
  {
    ner: "Архангай",
    sumd: [
      "Сонгох",
      "Цэцэрлэг",
      "Батцэнгэл",
      "Булган",
      "Жаргалант",
      "Ихтамир",
      "Өгийнуур",
      "Өлгий",
      "Өлзийт",
      "Өндөр-Улаан",
      "Тариат",
      "Төвшрүүлэх",
      "Хайрхан",
      "Хангай",
      "Хашаат",
      "Хотонт",
      "Цахир",
      "Цэнхэр",
      "Чулуут",
      "Эрдэнэбулган",
      "Эрдэнэмандал",
    ],
  },
  {
    ner: "Баян-Өлгий",
    sumd: [
      "Сонгох",
      "Өлгий",
      "Алтай",
      "Алтанцөгц",
      "Баяннуур",
      "Бугат",
      "Булган",
      "Буянт",
      "Дэлүүн",
      "Ногооннуур",
      "Сагсай",
      "Толбо",
      "Улаанхус",
      "Цагааннуур",
      "Цэнгэл",
    ],
  },
  {
    ner: "Баянхонгор",
    sumd: [
      "Сонгох",
      "Баянхонгор",
      "Баацагаан",
      "Баянбулаг",
      "Баянговь",
      "Баянлиг",
      "Баян-Овоо",
      "Баян-Өндөр",
      "Баянцагаан",
      "Богд",
      "Бөмбөгөр",
      "Бууцагаан",
      "Галуут",
      "Гурванбулаг",
      "Жаргалант",
      "Жинст",
      "Заг",
      "Өлзийт",
      "Хүрээмарал",
      "Шинэжинст",
      "Эрдэнэцогт",
    ],
  },
  {
    ner: "Булган",
    sumd: [
      "Сонгох",
      "Булган",
      "Баян-Агт",
      "Баяннуур",
      "Бугат",
      "Бүрэгхангай",
      "Гурванбулаг",
      "Дашинчилэн",
      "Могод",
      "Орхон",
      "Рашаант",
      "Сайхан",
      "Сэлэнгэ",
      "Тэшиг",
      "Хангал",
      "Хишиг-Өндөр",
      "Хутаг-Өндөр",
    ],
  },
  {
    ner: "Говь-Алтай",
    sumd: [
      "Сонгох",
      "Алтай",
      "Баян-Уул",
      "Бигэр",
      "Бугат",
      "Дарви",
      "Дэлгэр",
      "Жаргалан",
      "Есөнбулаг",
      "Тайшир",
      "Тонхил",
      "Төгрөг",
      "Халиун",
      "Хөхморьт",
      "Цогт",
      "Цээл",
      "Чандмань",
      "Шарга",
      "Эрдэнэ",
    ],
  },
  {
    ner: "Говьсүмбэр",
    sumd: ["Сонгох", "Чойр", "Сүмбэр", "Баянтал", "Шивээговь"],
  },
  {
    ner: "Дархан-Уул",
    sumd: ["Сонгох", "Дархан", "Орхон", "Хонгор", "Шарын гол"],
  },
  {
    ner: "Дорноговь",
    sumd: [
      "Сонгох",
      "Сайншанд",
      "Айраг",
      "Алтанширээ",
      "Даланжаргалан",
      "Дэлгэрэх",
      "Замын-Үүд",
      "Иххэт",
      "Мандах",
      "Өргөн",
      "Сайхандулаан",
      "Улаанбадрах",
      "Хатанбулаг",
      "Хөвсгөл",
      "Эрдэнэ",
    ],
  },
  {
    ner: "Дорнод",
    sumd: [
      "Сонгох",
      "Чойбалсан",
      "Баяндун",
      "Баянтүмэн",
      "Баян-Уул",
      "Булган",
      "Гурванзагал",
      "Дашбалбар",
      "Матад",
      "Сэргэлэн",
      "Халхгол",
      "Хөлөнбуйр",
      "Хэрлэн",
      "Цагаан-Овоо",
      "Чулуунхороот",
    ],
  },
  {
    ner: "Дундговь",
    sumd: [
      "Сонгох",
      "Мандалговь",
      "Адаацаг",
      "Баянжаргалан",
      "Говь-Угтаал",
      "Гурвансайхан",
      "Дэлгэрхангай",
      "Дэлгэрцогт",
      "Дэрэн",
      "Луус",
      "Өлзийт",
      "Өндөршил",
      "Сайнцагаан",
      "Сайхан-Овоо",
      "Хулд",
      "Цагаандэлгэр",
      "Эрдэнэдалай",
    ],
  },
  {
    ner: "Завхан",
    sumd: [
      "Сонгох",
      "Улиастай",
      "Алдархаан",
      "Асгат",
      "Баянтэс",
      "Баянхайрхан",
      "Дөрвөлжин",
      "Завханмандал",
      "Идэр",
      "Их-Уул",
      "Нөмрөг",
      "Отгон",
      "Сантмаргаз",
      "Сонгино",
      "Тосонцэнгэл",
      "Түдэвтэй",
      "Тэлмэн",
      "Тэс",
      "Ургамал",
      "Цагаанхайрхан",
      "Цагаанчулуут",
      "Цэцэн-Уул",
      "Шилүүстэй",
      "Эрдэнэхайрхан",
      "Яруу",
    ],
  },
  {
    ner: "Орхон",
    sumd: ["Сонгох", "Эрдэнэт", "Баян-Өндөр", "Жаргалант"],
  },
  {
    ner: "Өвөрхангай",
    sumd: [
      "Сонгох",
      "Арвайхээр",
      "Баруунбаян-Улаан",
      "Бат-Өлзий",
      "Баянгол",
      "Баян-Өндөр",
      "Богд",
      "Бүрд",
      "Гучин-Ус",
      "Есөнзүйл",
      "Зүүнбаян-Улаан",
      "Нарийнтээл",
      "Өлзийт",
      "Сант",
      "Тарагт",
      "Төгрөг",
      "Уянга",
      "Хайрхандулаан",
      "Хархорин",
      "Хужирт",
    ],
  },
  {
    ner: "Өмнөговь",
    sumd: [
      "Сонгох",
      "Даланзадгад",
      "Баяндалай",
      "Баян-Овоо",
      "Булган",
      "Гурвантэс",
      "Мандал-Овоо",
      "Манлай",
      "Ноён",
      "Номгон",
      "Сэврэй",
      "Ханбогд",
      "Ханхонгор",
      "Хүрмэн",
      "Цогт-Овоо",
      "Цогтцэций",
    ],
  },
  {
    ner: "Сүхбаатар",
    sumd: [
      "Сонгох",
      "Баруун-Урт",
      "Асгат",
      "Баяндэлгэр",
      "Дарьганга",
      "Мөнххаан",
      "Наран",
      "Онгон",
      "Сүхбаатар",
      "Түвшинширээ",
      "Түмэнцогт",
      "Уулбаян",
      "Халзан",
      "Эрдэнэцагаан",
    ],
  },
  {
    ner: "Сэлэнгэ",
    sumd: [
      "Сонгох",
      "Сүхбаатар",
      "Алтанбулаг",
      "Баруунбүрэн",
      "Баянгол",
      "Ерөө",
      "Жавхлант",
      "Зүүнбүрэн",
      "Мандал",
      "Орхон",
      "Орхонтуул",
      "Сайхан",
      "Сант",
      "Түшиг",
      "Хушаат",
      "Хүдэр",
      "Цагааннуур",
      "Шаамар",
    ],
  },
  {
    ner: "Төв",
    sumd: [
      "Сонгох",
      "Зуунмод",
      "Алтанбулаг",
      "Аргалант",
      "Архуст",
      "Батсүмбэр",
      "Баян",
      "Баяндэлгэр",
      "Баянжаргалан",
      "Баян-Өнжүүл",
      "Баянхангай",
      "Баянцагаан",
      "Баянцогт",
      "Баянчандмань",
      "Борнуур",
      "Бүрэн",
      "Дэлгэрхаан",
      "Жаргалант",
      "Заамар",
      "Лүн",
      "Мөнгөнморьт",
      "Өндөрширээт",
      "Сүмбэр",
      "Сэргэлэн",
      "Угтаалцайдам",
      "Цээл",
      "Эрдэнэ",
      "Эрдэнэсант",
    ],
  },
  {
    ner: "Увс",
    sumd: [
      "Сонгох",
      "Улаангом",
      "Баруунтуруун",
      "Бөхмөрөн",
      "Давст",
      "Завхан",
      "Зүүнговь",
      "Зүүнхангай",
      "Малчин",
      "Наранбулаг",
      "Өлгий",
      "Өмнөговь",
      "Өндөрхангай",
      "Сагил",
      "Тариалан",
      "Түргэн",
      "Тэс",
      "Ховд",
      "Хяргас",
      "Цагаанхайрхан",
    ],
  },
  {
    ner: "Ховд",
    sumd: [
      "Сонгох",
      "Ховд",
      "Алтай",
      "Булган",
      "Буянт",
      "Дарви",
      "Дөргөн",
      "Дуут",
      "Жаргалант",
      "Зэрэг",
      "Манхан",
      "Мөнххайрхан",
      "Мөст",
      "Мянгад",
      "Үенч",
      "Цэцэг",
      "Чандмань",
      "Эрдэнэбүрэн",
    ],
  },
  {
    ner: "Хөвсгөл",
    sumd: [
      "Сонгох",
      "Мөрөн",
      "Алаг-Эрдэнэ",
      "Арбулаг",
      "Баянзүрх",
      "Бүрэнтогтох",
      "Галт",
      "Жаргалант",
      "Их-Уул",
      "Рашаант",
      "Рэнчинлхүмбэ",
      "Тариалан",
      "Тосонцэнгэл",
      "Төмөрбулаг",
      "Түнэл",
      "Улаан-Уул",
      "Ханх",
      "Хатгал",
      "Цагааннуур",
      "Цагаан-Уул",
      "Цагаан-Үүр",
      "Цэцэрлэг",
      "Чандмань-Өндөр",
      "Шинэ-Идэр",
      "Эрдэнэбулган",
    ],
  },
  {
    ner: "Хэнтий",
    sumd: [
      "Сонгох",
      "Өндөрхаан",
      "Батноров",
      "Батширээт",
      "Баян-Адарга",
      "Баянмөнх",
      "Баян-Овоо",
      "Баянхутаг",
      "Биндэр",
      "Бор-Өндөр",
      "Галшар",
      "Дадал",
      "Дархан",
      "Дэлгэрхаан",
      "Жаргалтхаан",
      "Мөрөн",
      "Норовлин",
      "Өмнөдэлгэр",
      "Хэрлэн",
      "Цэнхэрмандал",
    ],
  },
];
