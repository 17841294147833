import React from "react";
import { MdEmail } from "react-icons/md";
import { PiPhone } from "react-icons/pi";

const GenericGroupIntro = ({ employee, content }) => {
  return (
    <main className="w-[98%] md:w-full bg-white p-2 rounded-xl flex flex-col md:flex-row p-8 shadow-md shadow-gray-300 border border-green-400">
      <div className="w-full md:w-2/6 flex flex-col justify-start items-center pt-4">
        <img
          src={
            employee.image
              ? employee.image
              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
          }
          alt="thumbnail"
          loading="lazy"
          className="rounded-md h-[250px] w-[250px] object-cover"
        />
        <div className="w-full flex flex-col mt-4 justify-start items-center">
          <h1 className="underline text-lg font-bold w-5/6 text-center">
            {employee.name}
          </h1>
          <p className="text-md text-center">{employee.position}</p>
        </div>
        <div className="w-full mt-4 flex justify-center items-center gap-4">
          <button className="text-md rounded-md bg-green-400 text-white p-2 hover:scale-105 duration-300 easy-in trasition">
            <PiPhone size={25} />
          </button>
          <button className="text-md rounded-md bg-green-400 text-white p-2 hover:scale-105 duration-300 easy-in trasition">
            <MdEmail size={25} />
          </button>
        </div>
      </div>
      <div className="w-full md:w-4/6 p-4">
        <p className="indent-8	text-md text-left mt-4">{content.about}</p>
        <div className="w-full">
          <div className="w-full p-2">
            {content?.additionals?.map((item, index) => (
              <div key={index} className="w-full">
                <h1 className="text-lg text-black font-bold text-left mt-4">
                  {item.name}
                </h1>
                {item?.services?.map((service, id) => (
                  <p
                    key={id}
                    className="text-md text-gray-500 font-bold text-left mt-4"
                  >
                    {service}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
};

export default GenericGroupIntro;
