import React, { useState } from "react";
import Logo from "../../../Images/company_logo.png";
import { useNavigate } from "react-router-dom";
import { CgArrowRight, CgMenuGridO } from "react-icons/cg";
import { PiArrowBendRightDownDuotone } from "react-icons/pi";
import { IoClose } from "react-icons/io5";

const navList = [
  {
    name: "Бидний тухай",
    path: "/aboutus",
    pages: [
      { name: "Мэндчилгээ", path: "/greeting" },
      { name: "Танилцуулга", path: "/introduction" },
      { name: "Хамт олон", path: "/employees" },
    ],
  },
  {
    name: "Үйл ажиллагаа",
    path: "/business",
    pages: [
      { name: "Геодези геоматикийн групп", path: "/geodesyGroup" },
      {
        name: "Барилга, хот байгуулалт архитектурын групп",
        path: "/architecture",
      },
      { name: "Газрын харилцаа, газрын үнэлгээнйи групп", path: "/landRating" },
      {
        name: "Инженер геологи - геотехник, гидрогеологи, геофизикийн групп",
        path: "/geologyGroup",
      },
      { name: "Тун удахгүй", path: "" },
      { name: "Сургалт, Танилцуулга", path: "/lesson" },
      { name: "Багажны түрээс", path: "/equipmentRent" },
      { name: "CORS (Байнгын ажиллагаатай суурин станц)", path: "/cors" },
    ],
  },
  {
    name: "Лаборатори",
    path: "/labratory",
    pages: [
      { name: "Хөрс судлалын лаборатори TL-78", path: "/lab-tl-78" },
      { name: "Хэмжилзүйн лаборатори CL-07", path: "/lab-cl-07" },
    ],
  },
  { name: "Мэдээлэл", path: "/news", pages: [] },
  {
    name: "Холбоо барих",
    path: "/contact",
    pages: [
      { name: "Санал хүсэлт", path: "/request" },
      { name: "Ажлын байр", path: "/resume" },
    ],
  },
];
const Navbar = ({ addCss }) => {
  const navigate = useNavigate();
  const [menuDrop, setMenuDrop] = useState(false);
  const [hoveringNav, setHoveringNav] = useState("");
  const [hoveringPage, setHoveringPage] = useState("");
  const [mobileMenuNav, setMobileMenuNav] = useState(false);
  const [mobileMenuNavPages, setMobileMenuNavPages] = useState("");

  const handleReplacePath = (path) => {
    // Replace the current path with the specified path
    navigate(path, { replace: true });
  };

  const handleNavigationWithoutPages = (item, path) => {
    if (item.pages.length === 0) {
      navigate(path, { replace: true });
    } else {
      return;
    }
  };

  return (
    <div
      onMouseLeave={() => {
        setHoveringNav("");
        setHoveringPage("");
        setMenuDrop(false);
      }}
      className={`${addCss} shadow-gray mt-4 flex w-[98%] md:w-full items-center justify-between rounded-xl bg-white p-2 text-white shadow-xl md:h-[70px] sticky top-2 z-20 `}
    >
      <div
        onClick={() => navigate("/")}
        className="flex cursor-pointer items-center justify-center md:gap-2"
      >
        <img src={Logo} alt="LOGO" className="h-10" />
        <div className="w-full h-full flex justify-end items-end pt-5">
          <h1 className="text-sm font-bold text-black md:text-xl">
            Инженер Геодези ХХК
          </h1>
        </div>
      </div>
      <div className="hidden md:flex">
        {navList.map((item, index) => (
          <button
            key={index}
            onClick={() => handleNavigationWithoutPages(item, item.path)}
            onMouseEnter={() => setHoveringNav(index)}
            className={`mx-3 text-black uppercase font-bold text-sm relative rounded-md p-2  ${
              hoveringNav === index && "bg-green-400 text-white"
            }`}
          >
            {item.name}
            {hoveringNav === index && item.pages.length !== 0 && (
              <div className="bg-white rounded-md mt-2 bg-green-400 absolute z-10 p-2 left-0 text-black shadow-xl shadow-[#424850]">
                <div className="relative w-full flex justify-start items-center pl-[20px]">
                  <div className="absolute h-[14px] w-[14px] bg-white rotate-45 top-[-15px] z-[-1]"></div>
                </div>
                {item?.pages.map((page, id) => (
                  <button
                    onClick={() => handleReplacePath(page.path)}
                    onMouseEnter={() => setHoveringPage(id)}
                    key={id}
                    className="w-96 text-left text-[16px] my-1 hover:bg-green-400 hover:text-white p-2 rounded-md flex justify-between items-center"
                  >
                    {page.name}
                    <div className="text-xl">
                      {hoveringPage === id && <CgArrowRight />}
                    </div>
                  </button>
                ))}
              </div>
            )}
          </button>
        ))}
      </div>
      <div className="gap-2 flex hidden md:flex">
        <button className="rounded-md bg-green-400 p-2 text-sm text-white ">
          Тун удахгүй
        </button>
        <button
          onClick={() => navigate("/login")}
          className="rounded-md bg-green-400 p-2 text-sm text-white"
        >
          Нэвтрэх
        </button>
      </div>
      <div className="gap-2 flex  md:hidden">
        <button
          onClick={() => setMenuDrop(!menuDrop)}
          className={`rounded-md bg-green-400 p-2 text-[22px] text-white`}
        >
          {menuDrop ? <IoClose /> : <CgMenuGridO />}
        </button>
      </div>

      <div
        className={`${
          menuDrop ? "translate-y-[1px] z-20" : "translate-y-[-700px] z-[-10]"
        } w-full left-0 duration-500 transition-transform ease-in-out absolute  top-[50px] bg-white rounded-br-lg rounded-bl-lg flex justify-center items-center flex-col gap-4 p-2 shadow-xl shadow-gray`}
      >
        {navList.map((item, index) => (
          <div key={index} className="w-full flex-col">
            <button
              onClick={() => {
                setMobileMenuNavPages(index);
                setMobileMenuNav(true);
                handleNavigationWithoutPages(item, item.path);
              }}
              className=" text-start text-black w-full p-2 flex justify-between items-center bg-gray-200 rounded-lg "
            >
              <h1 className="font-bold">{item.name}</h1>
              <p className="bg-green-400 p-2 rounded-lg text-white">
                {item.pages.length !== 0 ? (
                  <PiArrowBendRightDownDuotone />
                ) : (
                  <CgArrowRight />
                )}
              </p>
            </button>
            {mobileMenuNavPages === index &&
              item.pages.length !== 0 &&
              mobileMenuNav && (
                <div className="w-full rounded-md mt-1 flex flex-col text-black items-start">
                  {item.pages.map((page, id) => (
                    <button
                      onClick={() => handleReplacePath(page.path)}
                      className="text-left bg-gray-100 p-2 rounded-md my-1 w-[97%] flex justify-between items-center"
                      key={id}
                    >
                      {page.name}

                      <div className="rounded-md bg-green-400 p-2 text-white">
                        <CgArrowRight />
                      </div>
                    </button>
                  ))}
                </div>
              )}
          </div>
        ))}
        <button className="rounded-md bg-green-400 p-2 text-sm text-white w-4/6 ">
          Тун удахгүй
        </button>
        <button
          onClick={() => navigate("/login")}
          className="rounded-md bg-green-400 p-2 text-sm text-white w-4/6"
        >
          Нэвтрэх
        </button>
      </div>
    </div>
  );
};

export default Navbar;
