import React from "react";
import GenericPage from "../../utilityComponents/customComponents/GenericPage";
import Boss from "../../../../Images/employees/boss.jpeg";
import BoldText from "../../utilityComponents/customComponents/BoldText";

const Greetings = () => {
  return (
    <GenericPage name={"Мэндчилгээ"}>
      <main className="w-[98%] md:w-full bg-white p-2 rounded-xl flex md:flex-row flex-col p-8 shadow-md shadow-gray-300 border border-green-400">
        <div className="w-full md:w-2/6 flex flex-col justify-start items-center pt-4">
          <img
            src={Boss}
            alt="thumbnail"
            className="rounded-md h-[250px] w-[250px]"
          />
          <div className="w-full flex flex-col mt-4 justify-start items-center">
            <h1 className="underline">
              <BoldText>ЧОЙЖИЛЫН ЦЭРЭНБАТ</BoldText>
            </h1>
            <p className="text-sm  text-center">КОМПАНИЙ ҮҮСГЭН БАЙГУУЛАГЧ </p>
            <p className="text-sm  text-center">МОНГОЛ УЛСЫН ЗӨВЛӨХ ИНЖЕНЕР</p>
          </div>
        </div>
        <div className="w-full md:w-4/6 p-4">
          <h1 className="text-[30px] font-bold text-center">Мэндчилгээ</h1>
          <p className="indent-8	text-md text-gray-500 text-left mt-4">
            Манай улсын болон гадаад орны хамтран ажилладаг удаан жилийн зузаан
            нөхөрлөлийн түүхийг бүтээж яваа та бүхэнд эн өдрийн тэндийг
            дэвшүүлье.
          </p>
          <p className="indent-8	text-md text-gray-500 text-left mt-4">
            Манай компанийн явуулж байгаа эрдэм шинжилгээ, судалгааны хүрээ нь
            дэлхийн үүсэл гарал, хүн төрөлхтний оршин тогтнож, бүтээн байгуулж
            хөгжиж ирсэн түүх, газар ус байгалийн баялгийг өмчлөх, эзэмших,
            хамгаалах үйл ажиллагаатай салшгүй холбоотой байдаг.
          </p>
          <p className="indent-8	text-md text-gray-500 text-left mt-4">
            Геодези-геоматик, хэмжил зүйн шинжлэх ухаан нь төрөл бүрийн
            масштабын газрын зураглалын ажил, инженер геологи, геофизик,
            геотехникийн шинжлэх ухаан нь геологийн процесс, тектоник хүчний
            үйлчлэл, хот байгуулалт, инженерийн байгууламжийн буйр хөрсний
            тогтвортой бөгөөд хүрэлцээтэй нөхцөлийг судлах, архитектур хот
            байгуулалтын шинжлэх ухаан нь хүн амын суурьшилт, үйлдвэрлэх хүчний
            байршилт, бүс нутаг, хот байгуулалтын судалгаа төлөвлөлт, газар
            зохион байгуулалтын шинжлэх ухаан нь экосистемийг хамгаалах, газрын
            төлөв байдлын үнэлгээ, газрыг зохистой хуваарилах, хамгаалах, газар
            дээр явагдаж байгаа нийгмийн харилцаа эрэл хэрэгцээнд нийцүүлэн
            төлөвлөх хүрээнд шинжлэх ухаан технологийг хөгжүүлж байна.
          </p>
          <p className="indent-8	text-md text-gray-500 text-left mt-4">
            Эрдэм боловсролтой, эелдэг харилцаатай мэргэжилтэн та бүхнийг угтан
            авч, таны захиалгыг стандартын шалгуураар, чанарын өндөр түвшинд
            гүйцэтгэх болно.
          </p>
          <p className="indent-8	text-md text-gray-500 text-left mt-4">
            Эх орноо хөгжүүлэхийн төлөө хамтран ажиллаж байгаа та бүхэнд
            талархалтай байна, аз жаргал, ажлын амжилтыг хүсэн ерөөе.
          </p>
          <h1 className="text-end mt-8 font-bold">ТУГЧ ЧОЙЖИЛЫН ЦЭРЭНБАТ</h1>
        </div>
      </main>
    </GenericPage>
  );
};

export default Greetings;
