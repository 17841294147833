import React from "react";
import GenericPage from "../../utilityComponents/customComponents/GenericPage";
import GenericGroupIntro from "../../utilityComponents/customComponents/GenericGroupIntro";

const employee = {
  image:
    "https://web.archive.org/web/20220113093110im_/http://geo-mongol.mn/uploads/202105/043229721728660_1861035237259464_3533664088862019350_o.jpg",
  name: "Г.Эрдэнэцэцэг Инженер геологийн мэргэшсэн инженер",
  position: "Инженер геологийн группын ахлах инженер",
  phone: "",
  email: "",
};

const content = {
  about:
    "Инженер геологи-геотехник, гидрогеологи, геофизикийн групп нь Монгол улсын болон олон улсын стандарт, норм дүрмийн дагуу инженер геологи-геотехникийн хээрийн өрөмдлөг, туршилтын ажил, ул хөрсний лабораторийн шинжилгээг чанарын өндөр түвшинд хийж тайлан, дүгнэлт боловсруулахын зэрэгцээ судалгаа шинжилгээний ажилд олон улсын стандарт, дэвшилтэт техник технологи, арга аргачлалыг нэвтрүүлэн, инженер геологи-геотехникийн салбарыг хөгжүүлэх зорилттойгоор ажиллаж байна.",
  additionals: [
    {
      name: "Үйл ажилгаа:",
      services: [
        "– Бүс нутаг, хот суурингийн хөгжил төсөл, ерөнхий төлөвлөгөөнд зориулан явуулах инженер геологийн дунд, том масштабын зураглал инженер-гидрогеологи, инженер-геологи, инженер-геоэкологийн суурь судалгаа.",
        "– Барилга байгууламж, орон сууц, олон улсын болон I ангилалын авто болон төмөр зам, нисэх зурвас, гүүр-хоолой, газар доорхи болон газар дээрх ЦДАШ, салхин парк, нарны станц, цахилгаан болон дулааны станц, усан цахилгаан станц, усан сан, нуур цөөрмийн ул хөрсний инженер геологийн судалгаа",
      ],
    },
    {
      name:
        "Барилга байгууламжинд зориулсан инженер геологи-геотехникийн судалгаа:",
      services: [
        "– 16-н давхар буюу 51 метрээс дээш өндөртэй өндөр барилга болон уул уурхайн геотехникийн иж бүрэн судалгаа, шинжилгээ",
        "– Ул хөрсний нягтыг цооногт стандарт пенетрацийн туршилтаар /SPT, CPT, DCP/ тодорхойлно",
        "– Ул хөрсний хэв гажилтыг цооногт прессиометрийн туршилтаар /Pressuremeter test/ тодорхойлно",
        "– Ул хөрсний хэв гажилтыг талбайд болон цооногт штампын туршилтаар /Plate load test/ тодорхойлно",
        "– Ул хөрсний цахилгаан эсэргүүцлийг талбайд болон лабораторийн нөхцөлд тодорхойлно",
        "– Олон жилийн цэвдэг хөрсний иж бүрэн нарийвчилсан судалгаа",
        "– Бүх төрлийн барилга байгууламжийн буцаан булалт болон далангийн ул хөрсний нягтыг талбайд элсэн конусын аргаар /sandcone/ тодорхойлно",
      ],
    },
    {
      name: "Гидрогеологийн нарийвчилсан судалгаа:",
      services: [
        "– Уст цэгийн хайгуул судалгаа",
        "– Ахуйн болон үйлдвэрлэлийн зориулалттай инженерийн хийцтэй гүн өрмийн худаг гаргана",
        "– Гидрогеологийн шавхалт туршилтаар усны ундарга болон шүүрэлтийн коэффициентийг тодорхойлно",
      ],
    },
    {
      name: "Геофизикийн нарийвчилсан судалгаа:",
      services: [
        "– Цооногт болон талбайд хөрсний долгион тархах хурд /Vs/ буюу газар хөдлөлтийн судалгаа",
        "Барилга байгууламжинд зориулсан геофизикийн хэмжилт боловсруулалтын ажлыг АНУ-ын “Geometrics” компанийн Smartseis SE багажаар чулуулаг орчинд чичирхийллийн уян харимхай долгион үүсгэснээр хурдас чулуулгийн хэвтээ үе болон хил заагийг ул чулуулаг хүртэл нь долгион тархах хурд, хугацаа, далайц, давтамжаар газрын гүний хагарал, сэвсгэр хурдасны зузаан, түүний суулт, үе давхаргыг тодорхойлж байна",
        "Мөн дэлхийд тэргүүлэгч лабораторийн тоног төхөөрөмж үйлдвэрлэгч БНХАУ-ын Nanjing soil instrument, Итали улсын Controls Group, Турк улсын Utest, Энэтхэг улсын  Gauge India болон өрөмдлөгийн техник тоног төхөөрөмж үйлдвэрлэгч ОХУ-ын Геомаш, БНХАУ-ын Stonewell зэрэг компаниудтай хамтран ажиллаж, туршлага судлан, дэвшилтэт, техник технологийг үйл ажиллагаандаа нэвтрүүлэн ажилласаар байна.",
      ],
    },
  ],
};

const Geology = () => {
  return (
    <GenericPage
      name={"Инженер геологи-геотехник, гидрогеологи, геофизикийн групп"}
    >
      <GenericGroupIntro employee={employee} content={content} />
    </GenericPage>
  );
};

export default Geology;
