import React from "react";
import GenericPage from "../../utilityComponents/customComponents/GenericPage";
import GenericGroupIntro from "../../utilityComponents/customComponents/GenericGroupIntro";

const employee = {
  image:
    "https://web.archive.org/web/20220129074148im_/http://geo-mongol.mn/uploads/202101/065005983puujee.jpg",
  name: "Б.Пүрэвсүрэн ГЗБ-ын мэргэшсэн инженер",
  position: "Газар зохион байгуулалт, газрын үнэлгээний группын дарга",
  phone: "",
  email: "",
};

const content = {
  about:
    "Монгол улсын газар зохион байгуулалтын ерөнхий төлөвлөгөөний үйлдвэржилтийн бүс газрын төлөвлөлт, Увс, Говьсүмбэр, Дорнод аймгуудын газар зохион байгуулалтын ерөнхий төлөвлөгө, Увс, Сэлэнгэ аймгийн төвд суурин газрууд, баруун Алтайн тусгай хамгаалалтай зарим газар нутгийн газрын төлөв байдал, чанарын улсын хянан баталгаа, Сэлэнгэ аймгийн газрын нэгдмэл сангийн тооллого, Галбын говь бичил бүс нутгийн хөгжлийн төлөвийг тодорхойлох газар зохион байгуулалтын ерөнхий төлөвлөгөөг гүйцэтгэсэн. Эрдэнэт үйлдвэр, Монгол росцветмет нэгдэл,  South gobi sand ХХК зэрэг томоохон уурхайн эдэлбэр газар, Улаанбаатар хотоос хөшигийн хөндий хүртэлх хурдны замын газрын төлөв байдал, чанарын хянан баталгааны ажлыг хийж гүйцэтгэсэн.",
  additionals: [
    {
      name: "Үйл ажилгаа:",
      services: [
        "– Улс, аймаг, нийслэлийн газар зохион байгуулалтын ерөнхий төлөвлөгөө боловсруулах",
        "– Газрын нэгдмэл сангийн ангилал шинэчлэх",
        "– Газрын тооллого, кадастрын бүртгэл хийх",
        "– Газар нөхөн сэргээх үйл ажиллагаа",
        "– Газрын төлөв байдал, чанарын улсын болон захиалгат хянан баталгаа хийх",
        "– Газар зохион байгуулалт, кадастрын ажлаар мэргэжлийн зөвлөгөө өгөх",
        "– Хөрсний хүнд элемент болон агрохимийн шинжилгээ дүгнэлт, зөвлөгөө өгөх",
        "– Иргэн аж ахуйн нэгжийн газар ашиглалтын сэдэвчилсэн зураг хийх",
        "– Аж ахуйн дотоодын газар зохион байгуулалт хийх",
      ],
    },
  ],
};

function LandRating() {
  return (
    <GenericPage name={"Газрын харилцаа, газрын үнэлгээнйи групп"}>
      <GenericGroupIntro employee={employee} content={content} />
    </GenericPage>
  );
}

export default LandRating;
