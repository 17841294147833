import React from "react";
import GenericPage from "../../utilityComponents/customComponents/GenericPage";
import GenericLabIntro from "../../utilityComponents/customComponents/GenericLabIntro";

const employee = {
  image: "",
  name: "",
  position: "",
  phone: "+976 77288899",
  email: "soil_lab@geo-mongol.com",
};

const content = {
  about:
    "Манай лаборатори нь анх 2006 онд байгуулагдаж, 2020 онд Стандартчилал хэмжилзүйн газраас MNS ISO/IEC 17025:2018 стандартын шаардлагыг ханган итгэмжлэгдсэн ба геодезийн хэмжлийн бүх төрлийн багажинд шалгалт, тохиргоо, баталгаажуулалт болон засварын ажлыг гүйцэтгэж байна.",
  additionals: [
    {
      name: "",
      services: [
        "Тус лаборатори нь АНУ-ын Topcon, БНХАУ-ын Kolida, Япон улсын PENTAX фирмийн хэмжилзүйн лабораториудтай байнга олон улсын хэмжлийн нэгдмэл байдлыг хангах хуулийн дагуу лаборатори хоорондын нэгж дамжуулалтыг жил бүр хийж, коллиматорийн багажаа шалган нэгж дамжуулалтыг гүйцэтгэж эталон багажны нарийвчлалаа сайжруулан ажиллаж байна.",
        "Бид 2019 оноос GNSS, Лазер сканнер (ISO 17123:2018) хэмжлийн багажийг шалгаж тохируулж байгаа бөгөөд UAV (нисгэгчгүй нисэх төхөөрөмж)-ийн камерны шалгалт тохируулгыг гүйцэтгэж байна.",
      ],
    },
  ],
};

const table = {
  rows: [
    {
      name: "Өндөржилт хэмжил",
      contents: ["Электрон тоон нивелир", "Оптик нивелир", "Автомат нивелир"],
      limits: "0.7мм",
      tests: "0.7-3.0мм",
    },
    {
      name: "Зай хэмжил",
      contents: ["Электрон теодолит", "Оптик теодолит", "Электрон тахеометр"],
      limits: "1м-8км",
      tests: "1мм-5мм",
    },
    {
      name: "Өнцөг хэмжил",
      contents: ["Электрон теодолит", "Оптик теодолит", "Электрон тахеометр"],
      limits: "360°",
      tests: "0.5”-5”",
    },
  ],
};

function Cl07() {
  return (
    <GenericPage name={"Хэмжилзүйн лаборатори CL-07"}>
      <GenericLabIntro employee={employee} content={content} table={table} />
    </GenericPage>
  );
}

export default Cl07;
