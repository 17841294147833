import React from "react";
import LogoSlider from "./Sliders/LogoSlider";
import { useNavigate } from "react-router-dom";
import { CgFacebook, CgInstagram, CgYoutube } from "react-icons/cg";
import Copyright from "../../requiredComponents/Copyright/Copyright";

function Footer() {
  const navigate = useNavigate();
  function openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }
  return (
    <div className="w-full md:h-[300px] flex flex-col justify-between bg-white p-4 mt-10 rounded-tl-md rounded-tr-md shadow-xl shadow-gray">
      <div>
        <LogoSlider />
      </div>
      <div className="flex w-full flex-col md:flex-row justify-center md:justify-evenly items-center gap-2 mt-2">
        <div className="w-full md:w-3/6 flex flex-row gap-4">
          <button
            onClick={() =>
              openInNewTab("https://www.facebook.com/EngineeringgeodesyLLC/")
            }
            className="bg-green-400 text-white rounded-full p-2 w-12 h-12 flex justify-center items-center"
          >
            <CgFacebook size={22} />
          </button>
          <button
            onClick={() =>
              openInNewTab(
                "https://www.instagram.com/engineering_geodesy_llc/?hl=en"
              )
            }
            className="bg-green-400 text-white rounded-full p-2 w-12 h-12 flex justify-center items-center"
          >
            <CgInstagram size={22} />
          </button>
          <button
            onClick={() =>
              openInNewTab("https://www.youtube.com/watch?v=kIs3mKiA8vE")
            }
            className="bg-green-400 text-white rounded-full p-2 w-12 h-12 flex justify-center items-center"
          >
            <CgYoutube size={22} />
          </button>
        </div>
        <div className="w-full md:w-2/6 flex flex-col gap-2">
          <h1>Утас: +976 77278899</h1>
          <h1>Имайл: post@geo-mongol.mn</h1>
          <h1>
            Хаяг: Монгол улс, Улаанбаатар хот-16081 Ш/Х 26-246, Баянгол дүүрэг,
            6-р хороо,10 хороолол, Барилга 65
          </h1>
        </div>
      </div>
      <Copyright />
    </div>
  );
}

export default Footer;
