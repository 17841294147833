import React, { useEffect, useState } from "react";
import GenericPage from "../../utilityComponents/customComponents/GenericPage";
import { PiPhone } from "react-icons/pi";
import { MdEmail } from "react-icons/md";
import axios from "../../../../api/axios";
import { cdnUrl } from "../../../utility/Urls";

const Employees = () => {
  const [employeeList, setEmployeeList] = useState([]);

  useEffect(() => {
    const getEmployees = async () => {
      const { data } = await axios.get("/api/website/employees/display");
      if (data.success) {
        setEmployeeList(data.result);
      }
    };
    getEmployees();
  }, []);

  return (
    <GenericPage name={"ХАМТ ОЛОН"}>
      <main className="w-[98%] md:w-full bg-white p-8 rounded-xl flex shadow-md shadow-gray-300 flex-wrap border border-green-400">
        <div className="grid grid-cols-1 md:grid-cols-4  gap-4 mt-4">
          {employeeList.map((item, index) => (
            <div
              key={index}
              className="w-full flex flex-col justify-center items-center bg-white shadow-md shadow-gray-200 p-4 rounded-md gap-2"
            >
              <div className="w-full flex justify-center items-center relative">
                <img
                  loading="lazy"
                  src={`${cdnUrl}${item.thumbnail}`}
                  alt="profile"
                  className="w-48 h-48 object-cover rounded-lg"
                />

                <div className="w-full flex justify-center items-center absolute gap-2 bottom-1">
                  <a
                    href={`tel:${item.number}`}
                    className="bg-green-400 text-white p-2 rounded-md text-xl hover:scale-105 duration-300 easy-in trasition"
                  >
                    <PiPhone />
                  </a>
                  <a
                    href={`mailto:${item.email}`}
                    className="bg-green-400 text-white p-2 rounded-md text-xl hover:scale-105 duration-300 easy-in trasition"
                  >
                    <MdEmail />
                  </a>
                </div>
              </div>
              <div className="w-full mt-2">
                <h1 className="text-sm font-bold text-center">{item.name}</h1>
                <h1 className="text-sm text-center">{item.position}</h1>
              </div>
            </div>
          ))}
        </div>
      </main>
    </GenericPage>
  );
};

export default Employees;
