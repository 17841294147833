import React from "react";
import GenericPage from "../../utilityComponents/customComponents/GenericPage";
import GenericRequestPage from "../../utilityComponents/customComponents/GenericRequestPage";

function Request() {
  return (
    <GenericPage name={"Санал хүсэлт"}>
      <GenericRequestPage mode={"request"} />
    </GenericPage>
  );
}

export default Request;
