import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function Loader({ size, color }) {
  return (
    <AiOutlineLoading3Quarters
      className="animate-spin"
      size={size}
      color={color}
    />
  );
}

export default Loader;
