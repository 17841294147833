import React from "react";

const Copyright = () => {
  return (
    <div className="flex w-full items-center justify-center border-t border-[#666] p-4 text-black">
      <small>
        &copy; {new Date().getFullYear()} Инженер геодези ХХК. Бүх эрх хуулиар
        хамгаалагдсан.
      </small>
    </div>
  );
};

export default Copyright;
