import React, { useEffect, useState } from "react";
import GenericNewsPage from "../../utilityComponents/customComponents/GenericNewsPage";
import axios from "../../../../api/axios";
import { cdnUrl } from "../../../utility/Urls";
import { CgArrowRight } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

const News = () => {
  const navigate = useNavigate();
  const [newsList, setNewsList] = useState([]);
  useEffect(() => {
    const getNews = async () => {
      const { data } = await axios.get("/api/website/news/display");
      if (data.success) {
        setNewsList(data.result);
      }
    };
    getNews();
  }, []);
  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <GenericNewsPage name={"Мэдээлэл"}>
      <main className="w-[98%] md:w-full bg-white p-8 rounded-xl flex shadow-md shadow-gray-300 flex-wrap border border-green-400">
        <div className="grid grid-cols-1 md:grid-cols-4  gap-4 mt-4">
          {newsList.length !== 0 ? (
            newsList.map((news, index) => (
              <div
                key={index}
                className="p-4 rounded-md bg-white w-full md:w-[300px] h-[250px] flex flex-col shadow-xl shadow-gray justify-between"
              >
                <img
                  loading="lazy"
                  src={cdnUrl + news.thumbnail}
                  alt="news"
                  className="h-3/6 rounded-xl p-2 object-cover w-full"
                />
                <div className="w-full flex flex-col p-2 relative">
                  <p className="absolute underline italic text-[12px]  text-gray-400 top-2 right-2">
                    {news?.createdDate?.date} - {news?.createdDate?.time}
                  </p>
                  <div className="w-full flex flex-col text-wrap mt-4">
                    <h1 className="font-bold text-[14px]">
                      {truncate(news.title, 20)}
                    </h1>
                  </div>
                </div>
                <button
                  onClick={() => navigate(`/singleNews/${news._id}`)}
                  className="w-4/6 mx-auto ease-in-out duration-300 hover:scale-105 flex gap-2 justify-center items-center rounded-md p-1 border shadow-md shadow-gray bg-green-400 text-white"
                >
                  Мэдээ үзэх
                  <CgArrowRight size={25} />
                </button>
              </div>
            ))
          ) : (
            <h1>Одоогоор мэдээлэл алга байна</h1>
          )}
        </div>
      </main>
    </GenericNewsPage>
  );
};

export default News;
