import React from "react";
import GenericPage from "../../utilityComponents/customComponents/GenericPage";
import GenericLabIntro from "../../utilityComponents/customComponents/GenericLabIntro";

const employee = {
  image: "",
  name: "",
  position: "",
  phone: "+976 77288899",
  email: "soil_lab@geo-mongol.com",
};

const content = {
  about:
    "Тус лаборатори нь 2010 онд байгуулагдаж 2012 оноос хойш СХЗГ-аар 3 дахь удаагаа MNS ISO/IEC 17025:2018 стандартын шаардлагыг ханган итгэмжлэгдэж, сүүлийн үеийн тоног төхөөрөмж, дэвшилтэт техник, технологи болон чадварлаг боловсон хүчинтэйгээр үйлчлүүлэгч хувь хүн, албан байгууллагуудын хөрс, усны шинжилгээг чанартай гүйцэтгэж байна.",
  additionals: [
    {
      name: "Лабораторид хийгдэх шинжилгээний төрлүүд:",
      services: [
        "– Инженер геологийн судалгаанд зориулсан ул хөрсний физик, механик шинжилгээ",
        "– Далан, суурийн ул хөрсний нягтын хяналтын судалгаа",
        "– Газрын төлөв байдал, чанарын хянан баталгаанд зориулсан хөрсний химийн шинжилгээ",
        "– Байгаль орчны ерөнхий болон нарийвчилсан үнэлгээнд зориулсан хөрсний хими, физикийн шинжилгээ",
        "– Тариалангийн газрын үржил шимийг тодорхойлох агрохимийн шинжилгээ",
        "– Хөрсний хүнд элементийн бохирдол тодорхойлох хөрс, усны химийн шинжилгээ",
        "– Барилга байгууламжийн суурийн хийцэд нөлөөлөх хөрсний усанд уусах давсны шинжилгээ",
        "– Усны ерөнхий шинжилгээ",
        "– Чулууны физик, механик шинжилгээ",
        "– Хээрийн туршилт шинжилгээ.",
      ],
    },
  ],
};

function Tl78() {
  return (
    <GenericPage name={"Хөрс судлалын лаборатори TL-78"}>
      <GenericLabIntro employee={employee} content={content} />
    </GenericPage>
  );
}

export default Tl78;
