import React from "react";
import GenericPage from "../../utilityComponents/customComponents/GenericPage";
import GenericGroupIntro from "../../utilityComponents/customComponents/GenericGroupIntro";

const employee = {
  image: "",
  name: "Я.Жанболат Геодезийн мэргэшсэн инженер",
  position: "Геодезийн хэмжил зүйн лабораторийн мастер инженер",
  phone: "",
  email: "",
};

const content = {
  about:
    "Манай компаниас KOLIDA, PENTAX, TOPCON брэндүүдийн GNSS худалдан авсан тохиолдолд үнэгүй ашиглана.",
  additionals: [
    {
      name:
        "Үйлчилгээг идэвхжүүлэх хувь хүн, албан байгууллага доорх утсаар холбогдоно уу.",
      services: [
        "БАСС-EG01",
        "Уртраг- 106.5214274645",
        "Өргөрөг- 47.5457102341",
        "Солбилцолд байрлах EG01 БАСС-ын тэгшитгэн бодолтыг ITRF-2008 эринд хийсэн.",
        "Жилийн төлбөр: 250,000 ₮",
        "Сарын төлбөр: 29,000 ₮",
        "Утас: 91902989",
      ],
    },
  ],
};

function CORS() {
  return (
    <GenericPage name={"CORS (Байнгын ажиллагаатай суурин станц)"}>
      <GenericGroupIntro employee={employee} content={content} />
    </GenericPage>
  );
}

export default CORS;
