import axios from "../../../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const { data } = await axios.get("/api/admin/refresh", {
      withCredentials: true,
    });

    setAuth((prev) => {
      return {
        ...prev,
        accessToken: data.accessToken,
        permission: data.user.permission,
        user: data.user,
      };
    });
    return data.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
